import {
    ComingNext,
    Exercise,
    ExerciseResult as IExerciseResult,
    GameplayMode,
} from "@evidenceb/gameplay-interfaces";
import parse from "html-react-parser";
import React, { useContext, useEffect, useState } from "react";
import { configStore } from "../../../contexts/ConfigContext";
import { getFeedbackButtonText } from "../../../utils/feedbackUtils";
import ChatBubble from "../ChatBubble/ChatBubble";
import "./ExerciseResult.scss";

type ExerciseResultProps = {
    exerciseResult: IExerciseResult<any>;
    exercise: Exercise<any, any>;
    onGoToNextExercise?: () => void;
    comingNext?: ComingNext;
};

const ExerciseResult: React.FC<ExerciseResultProps> = ({
    exercise,
    exerciseResult,
    onGoToNextExercise,
    comingNext,
}) => {
    const [messageState, setMessageState] = useState("typing");
    useEffect(() => {
        setTimeout(() => setMessageState("display"), 1000);
    }, []);

    return (
        <> 
            <Instruction exercise={exercise} />
            {messageState === "typing" ? (
                <EvaluatingResponse />
            ) : (
                <>
                    <Result
                        exercise={exercise}
                        exerciseResult={exerciseResult}
                    />
                    {
                        exercise.executionOptions?.showFeedback ? 
                        <Feedback
                            exerciseResult={exerciseResult}
                            exercise={exercise}
                        /> : null
                    }
                    <GoToNext
                        comingNext={comingNext}
                        onGoToNextExercise={onGoToNextExercise}
                    />
                </>
            )}
        </>
    );
};
export default ExerciseResult;

function Instruction({ exercise }: Pick<ExerciseResultProps, "exercise">) {
    // TODO: check when instruction can be undefined
    return exercise.instruction ? (
        <ChatBubble
            className="ExerciseResultInstruction"
            direction="left"
            botAvatar="visible"
            color="var(--chatbot-color-bot-bubble)"
        >
            <p>{parse(exercise.instruction.$html)}</p>
            {exercise.messages?.length ? (
                <div className="additional-instructions">
                    {exercise.messages.map((message) => (
                        <p key={message.$html}>{parse(message.$html)}</p>
                    ))}
                </div>
            ) : null}
        </ChatBubble>
    ) : null;
}

function Result({
    exercise,
    exerciseResult,
}: Pick<ExerciseResultProps, "exercise" | "exerciseResult">) {
    const { config: { i18n } } = useContext(configStore);
    const isLastTry =
        exerciseResult.try === exercise.executionOptions?.numberOfTries;

    return (
        <ChatBubble className="ExerciseResultResult" direction="right">
            <exercise.Gameplay
                mode={GameplayMode.NonInteractive}
                data={exercise.data}
                exerciseResult={exerciseResult}
                nonInteractiveOptions={{
                    showCorrectAnswer:
                        isLastTry &&
                        (exercise.executionOptions?.showCorrectAnswer ?? false),
                    // TODO: should we show the correction before the last try?
                    showCorrection:
                        isLastTry &&
                        (exercise.executionOptions?.showCorrection ?? false),
                    showCorrectness:
                        exercise.executionOptions?.showCorrectness ?? false,
                }}
                showCorrectAnswer={
                    isLastTry &&
                    (exercise.executionOptions?.showCorrectAnswer ?? false)
                }
                // TODO: make non-required
                onTrackingEvent={() => null}
                i18n={{
                    ...i18n.gameplays.generic,
                    ...i18n.gameplays[exercise.type],
                }}
            />
        </ChatBubble>
    );
}

function EvaluatingResponse() {
    return (
        <ChatBubble
            className="EvaluatingResponse"
            direction="left"
            botAvatar="hidden"
            color="var(--chatbot-color-bot-bubble)"
        >
            <div className="loading" style={{ width: "30px" }} />
        </ChatBubble>
    );
}

function Feedback({
    exercise,
    exerciseResult,
}: Pick<ExerciseResultProps, "exercise" | "exerciseResult">) {
    return exerciseResult.feedback ? (
        <ChatBubble
            className="ExerciseResultFeedback"
            direction="left"
            botAvatar="hidden"
            color={
                exerciseResult.correct
                    ? "var(--chatbot-color-bot-bubble-correct)"
                    : "var(--chatbot-color-bot-bubble-incorrect)"
            }
            textColor={exerciseResult.correct ? "white" : undefined}
        >
            {exerciseResult.feedback.title}
        </ChatBubble>
    ) : null;
}

export function GoToNext({
    onGoToNextExercise,
    comingNext,
}: Pick<ExerciseResultProps, "onGoToNextExercise" | "comingNext">) {
    const { config } = useContext(configStore);
    return onGoToNextExercise ? (
        <div className="ExerciseResultGoToNext">
            <button onClick={() => onGoToNextExercise()}>
                {getFeedbackButtonText(comingNext!, config)}
            </button>
        </div>
    ) : null;
}
