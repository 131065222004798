import React, { useEffect, useContext } from "react";
import Button from "../../components/Button/Button";
import {configStore} from '../../contexts/ConfigContext';
import {useHistory} from 'react-router-dom';
import "./Logout.scss";

const Logout = () => {

    const {config} = useContext(configStore);
    const history = useHistory();

    useEffect(() => {

    }, [])

    return <div id="logout">
        <img src={config.logos.header_client} />
        <p>Message de déconnexion</p>
        {/* <Button style='primary' label={"Lien vers l'extérieur"} centeredLabel={true}  onClick={() => history.push(config.i18n.misc.logoutUrl)}/> */}
    </div>;
}

export default Logout;
