import React, { useContext, Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Pages
import HomeTeacher from "../Home/HomeTeacher/HomeTeacher";
import HomeStudent from "../Home/HomeStudent/HomeStudent";
import ModuleList from "../ModuleList/ModuleList";
import DashboardPage from "../DashBoard/Dashboard";
import ContentPage from "../ContentPage/ContentPage";
import SingleExercisePlayer from "../SingleExercisePlayer/SingleExercisePlayer";
import Logout from "../Logout/Logout";
import Error from "../Error/Error";
import TeacherPlaylistPlayer from "../PlaylistPlayer/TeacherPlaylistPlayer";
// import StudentPlaylistPlayer from "../PlaylistPlayer/StudentPlaylistPlayer";
import ModuleListStudent from "../ModuleListStudent/ModuleListStudent";
// Components
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Dev from "../../components/Dev/Dev";
import BanditManchotPlaylistPlayer from "../PlaylistPlayer/BanditManchotPlayer";

import { configStore,  } from "../../contexts/ConfigContext";
import useSetContexts from "../../hooks/useSetContexts";
import "./Root.scss";
import { getLabel, getUrl } from "../../utils/dataRetrieval";
import { sessionStore } from "../../contexts/SessionContext";

function Root() {
   /*  console.log = () => {}; */
    const { config } = useContext(configStore);
    const { status } = useSetContexts();
    const { session: { userType } } = useContext(sessionStore);
    const [ displayHeader, setDisplayHeader ] = useState<boolean>(true);

    useEffect(() => {
        setDisplayHeader(!config.auth.displaySignIn)
    }, [config.auth.displaySignIn])

    return (
        <Router>
            <Dev />
            {status.type === "fetched" ? (
                <Switch>
                    <Route
                        path="/player/teacher/:moduleId/:objectiveId"
                        children={<TeacherPlaylistPlayer />}
                    />
                    <Route
                        path="/player/bandit-manchot/:moduleId"
                        children={<BanditManchotPlaylistPlayer />}
                    />
                    <Route
                        path="/play/:shell/:exerciseId"
                        children={<SingleExercisePlayer />}
                    />
                    <Route
                        path="/error"
                        children={<Error
                            type={"error"}
                            errors={[{info: "An unknow error has occured", type: "unknown"}]}
                        />}
                    />

                    <Fragment>
                        <div className="content">
                            {
                                displayHeader ? 
                                <Header
                                    logo={config.logos.header_client}
                                    pages={config.pages}
                                /> : null
                            }                            
                            {config.pages.map((page, i) => (
                                page.type === "HOME" ? 
                                    <Route 
                                        key={`route-${i}`}
                                        path={`/${getUrl(page, userType)}`}
                                        exact
                                        render={ () =>
                                            userType === "STUDENT" ? 
                                                <HomeStudent title={`${config.client_name} - ${getLabel(page, userType)}`}/> 
                                            :
                                                <HomeTeacher title={`${config.client_name} - ${getLabel(page, userType)}`} />
                                        }
                                    />
                                : page.type === "MODULELIST" ? 
                                    <Route
                                      key={`route-${i}`}
                                      path={`/${getUrl(page, userType)}`}
                                      render={ () =>
                                        userType === "STUDENT" ? 
                                            <ModuleListStudent title={`${config.client_name} - ${getLabel(page, userType)}`}/>
                                        :
                                            <ModuleList title={`${config.client_name} - ${getLabel(page, userType)}`} />
                                      }
                                    />
                                : page.type === "DASHBOARD" && config.features.teacherDashboard && userType === "TEACHER"? 
                                    <Route
                                        key={`route-${i}`}
                                        path={`/${getUrl(page, userType)}`}
                                        render={() =>
                                            <DashboardPage title={`${config.client_name} - ${getLabel(page, userType)}`}/>
                                        }
                                    />
                                : page.type === "CONTENTPAGE" && page.authorized?.find(user => user === userType)?
                                    <Route
                                      key={`route-${i}`}
                                      path={`/${getUrl(page, userType)}`}
                                      render={ () =>
                                        <ContentPage title={`${config.client_name} - ${getLabel(page, userType)}`}/>
                                    }
                                    />
                                : page.type === "LOGOUT" ?
                                    <Route
                                      key={`route-${i}`}
                                      path={`/${getUrl(page, userType)}`}
                                      render={ () =>
                                        <Logout /> 
                                    }
                                    />
                                    : null
                            ))}

                        </div>
                        <Footer
                            logos={config.logos}
                            pages={config.pages}
                            contact={config.contact}
                            i18n={{
                                contact: config.i18n.misc.contact,
                                developedBy: config.i18n.misc.developedBy,
                            }}
                        />
                    </Fragment>
                </Switch>
            ) : status.type === "error" ? (
                <Error type={status.type} errors={status.errors} />
            ) : (
                <Loader />
            )}
        </Router>
    );
}

export default Root;
