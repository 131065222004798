import React, { useEffect, FormEvent, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { configStore } from '../../../contexts/ConfigContext';
import { dashboardStore } from '../../../contexts/DashboardContext';
import SimpleBar from 'simplebar-react';
import './Search.scss';

interface IUser{
    firstname:string;
    lastname:string;
    id: string;
    classroom: {
        id: string;
        name: string;
    }
}

const Search: React.FC = () => {
    const history = useHistory();
    const { config } = useContext(configStore);
    const { dashboard } = useContext(dashboardStore);
    const [value, setValue] = useState<string>('');
    const [students, setStudents] = useState<IUser[]>([]);
    const [results, setResults] = useState<IUser[]>([]);
    const ref = useRef<HTMLDivElement>(null)

    const getAllStudents = () => {
        let allStudents:IUser[] = [];
        dashboard.classrooms.forEach(classroom => {            
            classroom.modulesList.forEach(ml => {
               for( const id in ml.students){
                   let duplicate = allStudents.find( e => {
                        return e.id === id && e.classroom.id === classroom.id
                   })
                   if(!duplicate){
                        allStudents.push({
                            firstname: ml.students[id].firstname,
                            lastname: ml.students[id].lastname,
                            id: ml.students[id].id,
                            classroom:{
                                id: classroom.id,
                                name: classroom.name
                            }
                        })
                   }
               }
            })
        })
        setStudents(allStudents)
    }

    const onChange = (e: FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value)
        if (e.currentTarget.value.trim() !== "") {
            let filterdResults:IUser[] = students.filter(student => {
                let completeName = `${student.firstname.toLowerCase().trim()} ${student.lastname.toLowerCase().trim()}`;
                if ( completeName.includes(e.currentTarget.value.toLowerCase().trim()) ) {
                    return true
                } else {
                    return false
                }
            })
            setResults(filterdResults)
        } else {
            setResults([])
        }

    }

    const reset = () => {
        setResults([])
        setValue('')
    }

    const goToStudentDetails = (student: IUser) => {
        
        history.push(`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.student}/${student.classroom.id}/${student.id}`)
        reset()
    }

    const handleClickOutside = (e: globalThis.MouseEvent): void => {
        if (ref && ref.current && !(ref.current! as any).contains(e.target)) {
            reset()
        }
    }

    useEffect(() => {
        getAllStudents();
        return () => {
            document.removeEventListener('click', (e) => handleClickOutside(e))
        }
    }, [])

    useEffect(() => {
        document.addEventListener("click", (e) => handleClickOutside(e));
    }, [ref])

    return (
        <div className="search">
            <div className="bar">
                <span className="material-icons">search</span>
                <input placeholder={config.i18n.dashboard?.common.searchStudent} type="search" value={value} onChange={onChange} />
            </div>
            <div ref={ref} className={`list ${results.length ? 'show' : ''}`}>
                <SimpleBar autoHide={false} forceVisible={true}>
                    <ul>
                        {
                            results.map((student, i) =>
                                <li key={'result' + i} onClick={() => goToStudentDetails(student)}>
                                    <span className="material-icons icon">person</span>
                                    <span className="name">{student.firstname} {student.lastname} ({student.classroom.name})</span>
                                </li>
                            )
                        }
                    </ul>
                </SimpleBar>
            </div>
        </div>
    )
}

export default Search
