import { useContext, useEffect, useState } from "react";

//Contexts
import { configStore } from "../contexts/ConfigContext";
import { contentPagesStore } from "../contexts/ContentPagesContext";
import { dataStore } from "../contexts/DataContext";
import { homeStore } from "../contexts/HomeContext";
import { sessionStore } from "../contexts/SessionContext";

//Interfaces
import { VisibilityStatus } from "@evidenceb/gameplay-interfaces";
import { Config, MicroServices } from "../interfaces/Config";
import { ContentPage } from "../interfaces/ContentPage";
import { RawData } from "../interfaces/Data";
import { Home } from "../interfaces/Home";
import { ErrorLog, Status } from "../interfaces/Status";
import { ExternalUser, User, Classroom } from "../interfaces/User";

//Utils
import { getExercisesWithAvailableGameplays } from "../utils/fetch-gameplays";
import { applyTheme, Theme } from "../utils/theme-handler";
import windowLogger from "../utils/window-logger";
import { getData } from '../utils/request-data';
import * as localStorage from "../utils/localStorage";
import { clearLocalStorage, resetUser, deleteClass } from "../utils/dev-tools";
import { getContext } from "../utils/sentry";

import * as Sentry from "@sentry/react";
import { v4 as uuid } from "uuid";
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";
import chatbotTheme from "./chatbotTheme";
import config from "../config";
import { msConfigResolver } from "../utils/ms-config-resolver";

export interface GlobalConfig {
    config: Config;
    home: Home;
    contentPages: ContentPage[];
    theme: Theme;
}

export default function useSetContexts() {
    const { setData } = useContext(dataStore);
    const { setHome } = useContext(homeStore);
    const { setContentPages } = useContext(contentPagesStore);
    const { setConfig } = useContext(configStore);
    const { setSession } = useContext(sessionStore);

    const [status, setStatus] = useState<Status>({
        type: "pending",
        errors: [],
    });

    useEffect(() => {

        const errorLog: ErrorLog[] = [];

        function isBlank(str: string) {
            return (!str || /^\s*$/.test(str));
        }

        // Parse the url to get the TOKEN/VERSION of the app & retrieve TOKEN/VERSION in the localStorage if they exist
        let queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);

        const token = urlParams.get('token') as string
        const version = urlParams.get('version') as string

        function setLocalSTorage(item: string | null, localItem: string | null, type: localStorage.Key) {
            
            if (item) {
                if (!localItem || localItem !== item) {
                    localStorage.setItem(type, item)
                }
            } else {
                if(type === localStorage.Key.VERSION && token){
                    localStorage.setItem(type, "default");
                }
            }
        }

        async function initApp() {

            // Micro Services configuration
            let origin = window.location.origin;
            const msConfigs = await getData<MicroServices[]>(origin + '/json/msConfigs.json');
            const microServices = msConfigResolver(msConfigs, origin);

            if (!microServices) {
                Sentry.captureException(
                    "Cannot find matching microservices configuration"
                );
                errorLog.push({ info: `MS error`, type: "Microservices" });
                setStatus({ type: "error", errors: errorLog });
                return;
            }

            // localStorage setup
            if (!origin.includes("specimen")) {
                setLocalSTorage(token, localStorage.getItem<string>(localStorage.Key.TOKEN) ?? null, localStorage.Key.TOKEN)
                setLocalSTorage(version, localStorage.getItem<string>(localStorage.Key.VERSION) ?? null, localStorage.Key.VERSION)
            } else {
                let rawToken = await getData<{token:string}>(
                    process.env.REACT_APP_URL_DEMO_TOKEN as string, 
                    new Headers({ "x-evb-origin": process.env.REACT_APP_SPECIMEN_ORIGIN as string})
                )
                localStorage.setItem(localStorage.Key.TOKEN, rawToken.token)
                localStorage.setItem(localStorage.Key.VERSION, process.env.REACT_APP_SPECIMEN_VERSION)
                setSession(curr => {
                    return {
                        ...curr,
                        userType: process.env.REACT_APP_USER_TYPE as "TEACHER" | "STUDENT"
                    }
                });
            }
            
            let localToken = localStorage.getItem<string>(localStorage.Key.TOKEN) as string;
            let localVersion = localStorage.getItem<string>(localStorage.Key.VERSION) as string | null;

            let authHeaders = new Headers({ "Authorization": 'Bearer ' + localToken })
            
            // Get & set user ROLE/id (id = sub)
            const externalUserInfo = await getData<ExternalUser>(microServices.endpoints.users + '/user-info/', authHeaders);
            console.log("EXTERNAL: USER INFO", externalUserInfo);

            if (!origin.includes("specimen") && externalUserInfo && externalUserInfo.role && externalUserInfo.sub) {
                setSession(curr => {
                    return {
                        ...curr,
                        userType: externalUserInfo.role.toUpperCase() as
                            | "TEACHER"
                            | "STUDENT",
                        evidencebId: externalUserInfo.sub,
                    };
                });
            } else {
                // TODO: error handling
            }

            // API: GET: USER
            const user = await getData<User>(
                microServices.endpoints.users + '/api/' + externalUserInfo.role + 's/' + externalUserInfo.sub + '/', authHeaders
            );
            console.log(`API: GET: USER (${externalUserInfo.role})`, user);

            // SENTRY
            if(!origin.includes("specimen")){
                Sentry.setContext("userInfo", {
                    token: localStorage.getItem<string>(localStorage.Key.TOKEN),
                    evidenceb_id: externalUserInfo.sub,
                    version: localStorage.getItem<string>(
                        localStorage.Key.VERSION
                    ),
                    userType: externalUserInfo.role.toUpperCase(),
                });
            }

            // if no error is catch, get & set ressources and send fetched status, if error(s) send error status and log to display appropriate error page
            // TODO : better erros handling for ressources
            if (errorLog.length === 0) {

                // set custom headers accordingly, origin must have a valid keyword in it that the back can detect, e.g. "adaptivlangue"
                let originheaders = new Headers({
                    "x-evb-origin": origin
                })
                
                // Sequence of async fetch

                // Call Ms Ressources to get config.json
                const globalUrl = config.configJsonUrl ??
                `${microServices.endpoints.content}/content?token=${encodeURIComponent(localToken)}&target=config${localVersion !== "default" ? '&version=' + localVersion : ''}`
                
                const global = await getData<GlobalConfig>( globalUrl, originheaders );

                if (!global) Sentry.captureException("Cannot load config :", getContext({ Config: { url: globalUrl } }));
                // TODO : errors are catch but not set in errorLog, fix this at some point
                
                //Add MicroServices to global.config
                global.config.msConfigs = microServices;
                console.log("MOD", global.config.auth);
                
                // Check if User needs to signIn
                if (global.config.auth.mode === "REGISTER") {

                    let showSignIn = false;

                    if (user) {
                        if (externalUserInfo.role === "teacher") {
                            if (isBlank(user.first_name) || isBlank(user.last_name)) {
                                showSignIn = true
                            }
                        }
                        if (externalUserInfo.role === "student") {

                            const provider = global.config.declinaison;
                            
                            let isClassroom = false;
                            for(const id of user.classrooms){
                                const classroom = await getData<Classroom>( microServices.endpoints.users + '/api/classrooms/' + id, authHeaders )
                                if(classroom.provider === provider){
                                    isClassroom = true
                                }
                            }
                            if ( isBlank(user.first_name) || isBlank(user.last_name) || !isClassroom) {
                                showSignIn = true
                            }
                        }
                    }
                    global.config.auth.displaySignIn = showSignIn;

                } else if (global.config.auth.mode === "REGISTER_NAMEONLY") {
                    if ( user && (isBlank(user.first_name) || isBlank(user.last_name) ) ){
                        global.config.auth.displaySignIn = true;
                    }else{
                        global.config.auth.displaySignIn = false;
                    }
                } else {
                    global.config.auth.displaySignIn = false;
                }

                // Call Ms Ressources to get data.json
                // TODO : errors are not catch in removeNonVisible, fix this at some point
                const data = removeNonVisible(
                    await getData<RawData>(
                        config.dataJsonUrl ??
                        `${microServices.endpoints.content}/content?token=${encodeURIComponent(localToken)}&target=questions${localVersion !== "default" ? '&version=' + localVersion : ''}`, originheaders
                    )
                );

                registerDebugUtils(data);

                const exercises = await getExercisesWithAvailableGameplays(
                    data.exercises,
                    (exercise, reason) => {
                        windowLogger.error(
                            `Exercise could not be imported: ${reason}`,
                            exercise.id
                        );
                    }
                );

                // Retrieve and instanciate AI
                const banditManchot = await BanditManchotWhisperer.initBanditManchot(global.config.ai!, { ...data, exercises })

                if (errorLog.length === 0) {

                    applyTheme({ ...global.theme, chatbot: chatbotTheme }); //TODO: fallback theme, theme errors handler
                    setConfig({ ...global.config });
                    setHome(global.home);
                    setContentPages(global.contentPages);

                    setData({
                        modules: data.modules,
                        objectives: data.objectives,
                        activities: data.activities,
                        exercises,
                    });
                    
                    setSession(curr => {
                        return {
                            ...curr,
                            banditManchot,
                            sessionId: uuid(),
                            userId: user.id,
                            externalId: externalUserInfo.external_id,
                            userProvider: user.provider,
                            appProvider: global.config.declinaison,
                            flags: {
                                useHistoryFrom: "LRS"
                            }
                        }
                    });
                    setStatus({ type: "fetched", errors: [] });

                } else {
                    setStatus({ type: "error", errors: errorLog })
                }

            } else {
                setStatus({ type: "error", errors: errorLog })
            }
        }

        initApp();

    }, [setConfig, setContentPages, setData, setHome]);

    return { status };
}


function removeNonVisible(data: RawData): RawData {
    const exercises = data.exercises.filter(isVisible);
    const existsInExercises = existsIn(exercises);
    const activities = data.activities.filter(isVisible).map((activity) => ({
        ...activity,
        exerciseIds: activity.exerciseIds.filter(existsInExercises),
    }));
    const existsInActivities = existsIn(activities);
    const objectives = data.objectives.filter(isVisible).map((objective) => ({
        ...objective,
        activityIds: objective.activityIds.filter(existsInActivities),
    }));
    const existsInObjectives = existsIn(objectives);
    const modules = data.modules.filter(isVisible).map((module) => ({
        ...module,
        objectiveIds: module.objectiveIds.filter(existsInObjectives),
    }));
    return { modules, objectives, activities, exercises };
}

function isVisible(object: { visibilityStatus: VisibilityStatus }): boolean {
    return object.visibilityStatus === VisibilityStatus.Visible;
}

function existsIn(elements: { id: string }[]) {
    const ids = new Set(elements.map((element) => element.id));
    return (id: string): boolean => ids.has(id);
}

function registerDebugUtils(data: RawData) {
    (window as any).DEBUG_UTILS = {
        getModuleById: (id: string) =>
            data.modules.find((module) => module.id === id),
        getObjectiveById: (id: string) =>
            data.objectives.find((objective) => objective.id === id),
        getActivityById: (id: string) =>
            data.activities.find((activity) => activity.id === id),
        getExerciseById: (id: string) =>
            data.exercises.find((exercise) => exercise.id === id),
        resetUser: (url:string, userType: string | null) => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Reset user",
                level: Sentry.Severity.Info,
            });
            //TODO: better handling of url parameter
            resetUser(url, userType)
        },
        deleteClass: (url:string, id: string) => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Class deleted",
                level: Sentry.Severity.Info,
            });
            //TODO: better handling of url parameter
            deleteClass(url, id)
        },
        clearLocalStorage: () => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Clear local storage",
                level: Sentry.Severity.Info,
            });
            clearLocalStorage()
        },
    };
}