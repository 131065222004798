import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { ActivityShell, Playlist } from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../contexts/ConfigContext";
import { getLastResults } from "../../utils/exerciseUtils";
import useTeacherPlaylistManager from "../../hooks/useTeacherPlaylistManager";
import PlayerHeader from "../../components/PlayerHeader/PlayerHeader";
import TeacherProgressBar from "../../components/TeacherProgressBar/TeacherProgressBar";
import WizardShell from "../../components/WizardShell/WizardShell";
import ChatbotShell from "../../components/ChatbotShell/ChatbotShell";
import ExerciseIdentifier from "../../components/ExerciseIdentifier/ExerciseIdentifier";
import PlaylistSummary from "../../components/PlaylistSummary/PlaylistSummary";
import PlaylistNavigationBreadCrumbs from "../../components/PlaylistNavigationBreadcrumbs/PlaylistNavigationBreacrumds";

import "./PlaylistPlayer.scss";
import { getUrl } from "../../utils/dataRetrieval";
import { sessionStore } from "../../contexts/SessionContext";

const TeacherPlaylistPlayer: React.FC = () => {
    const { moduleId, objectiveId } = useParams<{
        moduleId: string;
        objectiveId: string;
    }>();
    const {
        playlist,
        goToNextExercise,
        goToExercise,
        recordCurrentExerciseResult,
    } = useTeacherPlaylistManager(moduleId, objectiveId);
    const Shell =
        playlist.activity?.shell === ActivityShell.Chatbot
            ? ChatbotShell
            : WizardShell;
    const { config } = useContext(configStore);
    const { session: { userType } } = useContext(sessionStore);

    return (
        <div className="playlist-player-container player-container">
            <PlayerHeader>
                {!isPlaylistFinished(playlist) && (
                    <PlaylistNavigationBreadCrumbs
                        goToExercise={goToExercise}
                        currentHierarchy={{
                            module: playlist.module,
                            objective: playlist.objective,
                            activity: playlist.activity,
                        }}
                        currentExerciseIndex={playlist.activity.exerciseIds.findIndex(
                            (id) => id === playlist.currentExercise!.id
                        )}
                        // Do not add activity details tooltip when using the
                        // Chatbot shell because the info is already included
                        // in the left panel
                        showActivityInfo={
                            playlist.activity?.shell !== ActivityShell.Chatbot
                        }
                    />
                )}
                <TeacherProgressBar
                    exerciseResults={getLastResults(playlist.exerciseResults)}
                    currentExercise={playlist.currentExercise}
                    exercises={playlist.exercises}
                />
                <div className="header_close">
                    <Link
                        to={`/${ getUrl( config.pages.find( page => page.type === "MODULELIST" )! , userType)}`}
                    >
                        <span className="material-icons">clear</span>
                        <span>{config.i18n.exerciseShell.close}</span>
                    </Link>
                </div>
            </PlayerHeader>

            <div className="main__wrapper">
                {!isPlaylistFinished(playlist) ? (
                    <main>
                        <Shell
                            currentExercise={playlist.currentExercise!}
                            currentTry={playlist.currentTry!}
                            comingNext={playlist.comingNext}
                            currentExerciseResult={
                                playlist.currentExerciseResult
                            }
                            onGoToNextExercise={goToNextExercise}
                            onExerciseResult={recordCurrentExerciseResult}
                            playlist={playlist}
                        />
                    </main>
                ) : (
                    <PlaylistSummary
                        exerciseResults={playlist.exerciseResults}
                    />
                )}
                {
                    config.debug?.exerciseIdentifier ? 
                        !isPlaylistFinished(playlist) ? (
                            <ExerciseIdentifier
                                moduleId={playlist.module.id}
                                objectiveId={playlist.objective.id}
                                activityId={playlist.activity.id}
                                exerciseId={playlist.currentExercise!.id}
                            />
                        ) : null 
                    : null
                }
            </div>
        </div>
    );
};

const isPlaylistFinished = (playlist: Playlist): boolean =>
    !playlist.currentExercise;

export default TeacherPlaylistPlayer;
