import React, { useState, useEffect, useContext, FormEvent, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { dataStore } from "../../../contexts/DataContext";
import { configStore } from "../../../contexts/ConfigContext";
import { dashboardStore } from "../../../contexts/DashboardContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { Classroom, ModuleCluster, ParamTypes } from "../../../interfaces/Dashboard";
import "./ClassDetails.scss";
import ProgressBarLink from "../ClassList/ProgressBarLink/ProgressBarLink";
import BarLink from "../ClassList/BarLink/BarLink";
import { getClusters, getModuleById } from "../../../utils/dataRetrieval";
import { fetchData, getData } from "../../../utils/request-data";
import * as localStorage from '../../../utils/localStorage';
import Loader from "../../../components/Loader/Loader";
interface StudentInDifficulty {
  firstname: string;
  lastname: string,
  id: string
}

const ClassDetails: React.FC<{ classrooms: Classroom[] }> = ({ classrooms }) => {
  let { classroomId } = useParams<ParamTypes>();
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);
  const { session } = useContext(sessionStore);
  const { dashboard: { clustering }, setDashboard } = useContext(dashboardStore);
  const [classroom, setClassroom] = useState<Classroom>();
  const [studentInDifficulty, setStudentInDifficulty] = useState<StudentInDifficulty[]>([]);
  const [className, setClassName] = useState<string | undefined>()
  const [editClassNameStatus, setEditClassNameStatus] = useState<"idle" | "edit" | "saving">("idle")
  const classNameInputRef = useRef<HTMLInputElement>(null)

  const getStudentsInDifficulties = (currentClassroom: Classroom | undefined) => {
    const table: { firstname: string; lastname: string, id: string }[] = [];
    currentClassroom?.modulesList.map((module) =>
      Object.keys(module.students).map(
        (studentId) => {
          if(module.students[studentId].studentInDifficulty){
            table.push({
              firstname: module.students[studentId].firstname,
              lastname: module.students[studentId].lastname,
              id: module.students[studentId].id
            })
          }
        }
      )
    );
    setStudentInDifficulty(table)
  };

  const onChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    setClassName(e.currentTarget.value)
  }

  const editClassName = async () => {
    let postHeaders = new Headers({
      "Authorization": 'Bearer ' + localStorage.getItem<string>(localStorage.Key.TOKEN),
      "Content-Type": "application/json"
    })
    let getHeaders = new Headers({
      "Authorization": 'Bearer ' + localStorage.getItem<string>(localStorage.Key.TOKEN),
      "x-evb-origin": window.location.origin,
      "version": config.declinaison
    })
    let body = {
      name: className
    }

    let updatedClass = await fetchData<Classroom>(config.msConfigs?.endpoints.users + '/api/classrooms/' + classroom?.id + '/', 'PATCH', body, postHeaders)
    console.log("API: CLASSROOMS: PATCH", updatedClass);

    const queryParams = config.features.clustering ? "" : "?enableclustering=false";
    const dashboard = await getData<any>(config.msConfigs?.endpoints.analytics + "/teacher/" + session.userId + "/classrooms_analytics/" + queryParams, getHeaders)
    setDashboard(dashboard)
    setEditClassNameStatus("idle")
  }

  useEffect(() => {
    const currentClassroom = classrooms.find((obj) => {
      return obj.id === classroomId;
    });
    setClassroom(currentClassroom);
    getStudentsInDifficulties(currentClassroom)
    console.log(currentClassroom);
  }, [classrooms, classroomId]);

  return (
    <div className="classrooms-details-container">

      <header>
        <div className="breadcrumbs">
          <ul>
            <li>
              <Link to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}`}>{config.i18n.dashboard?.common.classes}</Link>
            </li>
            <li>{config.i18n.dashboard?.common.class} {classroom?.name}</li>
          </ul>
        </div>
      </header>

      <div className="classroom-title">
        <h1>
          {
            editClassNameStatus === "idle" ?
              <div className="idle">
                <span className="name">{config.i18n.dashboard?.common.class} {classroom?.name}</span>
                {
                  config.features.classManagement ?
                    <button onClick={() => {
                      setEditClassNameStatus('edit')
                      setClassName(classroom?.name)
                      classNameInputRef.current?.focus()
                    }}><span className="material-icons icon"> drive_file_rename_outline </span></button>
                    : null
                }
              </div> : null
          }
          {
            editClassNameStatus === "edit" ?
              <div className="edit">
                <input ref={classNameInputRef} type="text" value={className} onChange={onChangeHandler} />
                <button onClick={() => {
                  editClassName()
                  setEditClassNameStatus('saving')
                }}>
                  <span className="material-icons icon"> check </span>
                </button>
                <button onClick={() => {
                  setClassName(classroom?.name)
                  setEditClassNameStatus("idle")
                }}><span className="material-icons icon"> close </span></button>
              </div> : null
          }
          {
            editClassNameStatus === "saving" ?
              <div className="saving"><Loader /></div> : null
          }
        </h1>
        {
          config.features.classManagement ?
            <div className="classCode">
              <span className="code">{config.i18n.dashboard?.classDetails.code} {classroom?.external_id}</span>
              <div className="tool-tip">
                <span className="text">{config.i18n.dashboard?.classDetails.codeMessage}</span>
                <span className="material-icons">help_outline</span>
              </div>
            </div>
            : null
        }
      </div>

        <div className="modules-container">

          <div className={`classroom-details-cards-container ${studentInDifficulty.length === 0 ? 'no-difficulties' : ''}`}>
            {classroom?.modulesList.length ? classroom?.modulesList.map((module, index) => {
              return (
                <div className="module-card" key={module.id}>
                  <div className="card-title">{`${getModuleById(module.id, data).title.short}`}</div>
                  <div className="body">
                    <ProgressBarLink
                      url={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroomId}/${module.id}`}
                      progression={(module.progression * 100).toFixed(1)}
                      title={`${config.i18n.dashboard?.common.progression}`}
                    />
                    {
                      config.features.clustering && clustering && clustering[classroomId][module.id] && typeof clustering[classroomId][module.id].error !== "undefined" &&
                        <BarLink
                          title={`${getClusters(clustering[classroomId][module.id] as ModuleCluster).length} ${config.i18n.dashboard?.classDetails.identifiedGroups}`}
                          url={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${classroomId}/${module.id}/${config.i18n.dashboard?.paths.groups}`}
                          id={index}
                        />
                    }
                    <div className="progression">
                      <div className="participants">
                        {module.participants} {`${config.i18n.dashboard?.classDetails.savedProgression}`}
                      </div>

                      <div className="progression-state">
                        <div className="progression-difficulties">{`${module.alert.hasDifficulties} ${config.i18n.dashboard?.classDetails.progressionRegression}`}</div>
                        <div className="progression-improved">{`${module.alert.hasImproved} ${config.i18n.dashboard?.classDetails.progressionAcceleration}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) : <div className="no-data">{config.i18n.dashboard?.common.noStudentsData}</div>}
          </div>
          
          {studentInDifficulty.length > 0 ?
            <div className="students-with-difficulties">
              <div className="title">
                <span className="material-icons">info</span>
                {config.i18n.dashboard?.classDetails.titleStudentInDifficulty}
              </div>
              {/** TODO : i18n */}
              <div className="student-list">
                { studentInDifficulty.map((student: { firstname: string; lastname: string, id: string }) => (
                    <Link key={student.id} to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.student}/${classroom?.id}/${student.id}`}>
                      <span className="material-icons">person</span>
                      <div className="name">
                        <span>{student.firstname} {student.lastname}</span>
                        <span>
                          {config.i18n.dashboard?.common.seeProfile}
                          <span className="material-icons">chevron_right</span>
                        </span>
                      </div>
                    </Link>
                )) }
              </div>
            </div>
            : null}

        </div>

    </div>
  );
};

export default ClassDetails;
