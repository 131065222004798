import React, { useContext, useEffect, useState } from "react";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import { getSublevelPool } from "../../utils/dataRetrieval";
import CustomSelect from "../CustomSelect/CustomSelect";
import Case from "case";
import {
    Activity,
    Exercise,
    Hierarchy,
    HierarchyIds,
    Objective,
} from "@evidenceb/gameplay-interfaces";
import parse from "html-react-parser";

import "./PlaylistNavigationBreadcrumbs.scss";

/**
 * Menu that allows navigation in any part of the hierarchy of exercises.
 */
const PlaylistNavigationBreadCrumbs: React.FC<{
    goToExercise: (hierarchy: Partial<HierarchyIds>) => void;
    currentHierarchy: Omit<Hierarchy, "exercise" | "isInitialTest">;
    currentExerciseIndex: number;
    showActivityInfo: boolean;
}> = ({
    goToExercise,
    currentHierarchy,
    currentExerciseIndex,
    showActivityInfo,
}) => {
    const { data } = useContext(dataStore);
    const {
        config: { i18n },
    } = useContext(configStore);

    return (
        <div className="player__nav-breadcrumbs">
            <CustomSelect
                onSelectOption={(moduleId) => {
                    goToExercise({ moduleId });
                }}
                selectedOption={
                    currentHierarchy.module.title.short ||
                    currentHierarchy.module.title.long
                }
            >
                {data.modules.map((module) => (
                    <option
                        key={module.id}
                        value={module.id}
                        selected={module.id === currentHierarchy.module.id}
                    >
                        {module.title.short || module.title.long}
                    </option>
                ))}
            </CustomSelect>

            <CustomSelect
                onSelectOption={(objectiveId) => {
                    goToExercise({
                        moduleId: currentHierarchy.module.id,
                        objectiveId,
                    });
                }}
                selectedOption={
                    currentHierarchy.objective.title.short ||
                    currentHierarchy.objective.title.long
                }
            >
                {getSublevelPool<Objective>(
                    currentHierarchy.module.objectiveIds,
                    data.objectives
                ).map((objective) => (
                    <option
                        key={objective.id}
                        value={objective.id}
                        selected={
                            objective.id === currentHierarchy.objective.id
                        }
                    >
                        {objective.title.short || objective.title.long}
                    </option>
                ))}
            </CustomSelect>

            <div className="activity-selector">
                <CustomSelect
                    onSelectOption={(activityId) => {
                        goToExercise({
                            moduleId: currentHierarchy.module.id,
                            objectiveId: currentHierarchy.objective.id,
                            activityId,
                        });
                    }}
                    selectedOption={
                        currentHierarchy.activity.title?.short || currentHierarchy.activity.title.long
                    }
                >
                    {getSublevelPool<Activity>(
                        currentHierarchy.objective.activityIds,
                        data.activities
                    ).map((activity) => (
                        <option
                            key={activity.id}
                            value={activity.id}
                            selected={
                                activity.id === currentHierarchy.activity.id
                            }
                        >
                            {activity.title.short}
                        </option>
                    ))}
                </CustomSelect>
                {showActivityInfo && (
                    <button className="activity-selector__tooltip">
                        <span className="material-icons activity-selector__icon">
                            info_outline
                        </span>
                        <div className="activity-selector__info">
                            <p>
                                {currentHierarchy.activity.title.long}
                                {currentHierarchy.activity.description &&
                                currentHierarchy.activity.title ? (
                                    <>{i18n.misc.colon}</>
                                ) : null}
                                <span>
                                    {parse(
                                        currentHierarchy.activity.description
                                            .$html
                                    )}
                                </span>
                            </p>
                        </div>
                    </button>
                )}
            </div>

            <CustomSelect
                onSelectOption={(exerciseId) => {
                    goToExercise({
                        moduleId: currentHierarchy.module.id,
                        objectiveId: currentHierarchy.objective.id,
                        activityId: currentHierarchy.activity.id,
                        exerciseId,
                    });
                }}
                selectedOption={`${Case.pascal(i18n.hierarchy.exercise.full)} ${
                    currentExerciseIndex + 1
                }`}
            >
                {getSublevelPool<Exercise<any, any>>(
                    currentHierarchy.activity.exerciseIds,
                    data.exercises
                ).map((exercise, index) => (
                    <option
                        key={exercise.id}
                        value={exercise.id}
                        selected={index === currentExerciseIndex}
                    >
                        {`${Case.pascal(i18n.hierarchy.exercise.full)} ${
                            index + 1
                        }`}
                    </option>
                ))}
            </CustomSelect>
        </div>
    );
};

export default PlaylistNavigationBreadCrumbs;
