import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import { Config } from "../interfaces/Config";

// Context Typing
interface ConfigContext {
    config: Config;
    setConfig: Dispatch<SetStateAction<Config>>;
}

// Initial State
const initialState: Config = {
    versioning: 0,
    declinaison: "",
    client_name:"",
    activate_contentPage: false,
    features:{
        teacherDashboard: true
    },
    pages: [],
    auth:{
        mode:"DIRECT_ACCESS",
        rightImage:"",
        displaySignIn: false
    },
    i18n: {
        auth:{
            student:{
                registerClass: {
                    title: "",
                    text: {$html:""},
                    classCode:{
                        info: {$html:""}
                    }
                },
                signIn:{
                    text: {$html:""}
                }
            },
            teacher:{
                signIn:{
                    title:"",
                    text: {$html:""}
                }
            },
            form:{
                classCode:"",
                error:{
                    codeClassError:{$html:""},
                    authError:{$html:""},
                    name:"",
                    firstname:""
                },
                button:{
                    start:"",
                    next:""
                },
                firstname:"",
                name:""
            }
        },
        dashboard: undefined,
        hierarchy: {
            module: {
                full: "",
                short: "",
            },
            objective: {
                full: "",
                short: "",
            },
            activity: {
                full: "",
                short: "",
            },
            exercise: {
                full: "",
                short: "",
            },
        },
        moduleList: {
            info: "",
            title: "",
            student: {
                message: {
                    default: { $html: "" },
                    visited: { $html: "" },
                },
                progression: "",
                successRate: "",
                start: "",
                continue: "",
            },
        },
        endPlaylist: {
            congratulations: "",
            completed: "",
            result: "",
            exerciseCorrect: "",
            exerciseIncorrect: "",
            moduleList: "",
            nextModule: "",
            dashboard: "",
        },
        exerciseShell: {
            close: "",
            feedback: {
                nextExercise: "",
                nextActivity: "",
                endPlaylist: "",
                retry: "",
            },
            tryAgain: { $html: "" },
            initialTestMessages: {
                start: { $html: "" },
                end: { $html: "" },
                btnStart: "",
                btnEnd: ""
            },
            emotionalReport: {
                frustrated: "",
                bored: "",
                confused: "",
                neutral: "",
                popupTitle: "",
                popupSendBtn: ""
            }
        },
        misc: {
            logout: "",
            logoutUrl:[],
            contact: "",
            developedBy: "",
            colon: "",
            userDataDownload: ""
        },
        chatbot: {
            welcomeMessages: [],
            start: { $html: "" },
            initialTestMessages: {
                start: { $html: "" },
                end: { $html: "" },
            },
        },
        gameplays: {
            generic: {
                btnValidate: "",
            },
        },
        drawer: {
            targetedDifficulties: "",
        },
    },
    logos: {
        avatar:"",
        header_client: "",
        endPlaylistIcon: "",
        footer_client: "",
        footer_evidenceb: "",
    },
    contact: "",
};

// Context init
const configStore = createContext<ConfigContext>({
    config: initialState,
    setConfig: () => null,
});

// Provider init
const { Provider } = configStore;

// Final setup
const ConfigProvider = ({ children }: { children: JSX.Element }) => {
    const [config, setConfig] = useState(initialState);
    useEffect(()=>{
        console.log("CONTEXT CONFIG", config);
        
    },[config])
    return <Provider value={{ config, setConfig }}>{children}</Provider>;
};

export { configStore, ConfigProvider };
