import React from "react";
import { ProgressBarProps } from "../../interfaces/Data";
import { getExerciseLastResult, hasResult } from "../../utils/exerciseUtils";

import "./TeacherProgressBar.scss";

const TeacherProgressBar: React.FC<ProgressBarProps> = ({
    exerciseResults,
    currentExercise,
    exercises,
}) => {
    return (
        <div className="teacher-progress-bar">
            <div className="progress-bar__gauge">
                <div
                    className="gauge__completed"
                    style={{
                        width:
                            "" +
                            (100 * exerciseResults.length) / exercises.length +
                            "%",
                    }}
                ></div>
            </div>

            {currentExercise ? (
                <div
                    className={`progress-bar__current-exercise ${
                        hasResult(currentExercise.id, exerciseResults)
                            ? getExerciseLastResult(
                                  exerciseResults,
                                  currentExercise.id
                              )!.correct
                                ? "current-exercise--correct"
                                : "current-exercise--incorrect"
                            : "current-exercise--solving"
                    }`}
                    style={{
                        left:
                            "" +
                            (100 *
                                exercises.findIndex(
                                    (exercise) =>
                                        exercise.id === currentExercise.id
                                )) /
                                exercises.length +
                            "%",
                    }}
                >
                    {hasResult(currentExercise.id, exerciseResults) ? (
                        <span className="material-icons">
                            {getExerciseLastResult(
                                exerciseResults,
                                currentExercise.id
                            )!.correct
                                ? "done"
                                : "clear"}
                        </span>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default TeacherProgressBar;
