import React, { useContext, useEffect, useState } from "react";
import ChatbotDrawer from "./Drawer/ChatbotDrawer";
import ExerciseResult, { GoToNext } from "./ExerciseResult/ExerciseResult";
import Welcome from "./Welcome";
import { CurrentExercise } from "./CurrentExercise";
import {
    Playlist,
    PlaylistExecutionStage,
    Shell,
} from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import { getExerciseById } from "../../utils/dataRetrieval";
import parse from "html-react-parser";
import "./ChatbotShell.scss";
import ChatBubble from "./ChatBubble/ChatBubble";
import { sessionStore } from "../../contexts/SessionContext";
import useEmotionalReport from "../EmotionalReport/useEmotionalReport";
import EmotionalReport from "../EmotionalReport/EmotionalReport";

interface ChatbotShell<Data, Answer> extends Shell<Data, Answer> {
    playlist: Playlist & { isInitialTest?: boolean };
    clearHistory?: () => void;
}

const ChatbotShell: React.FC<ChatbotShell<any, any>> = ({
    playlist,
    onExerciseResult,
    onGoToNextExercise,
    clearHistory,
}) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { session: { userType } } = useContext(sessionStore);
    const emotionalReportController = useEmotionalReport();
    const [started, setStarted] = useState(false);
    const [
        playlistHasInitialTest,
        setPlaylistHasInitialTest,
    ] = useState<boolean>();
    const [showWelcome, setShowWelcome] = useState<boolean>(true);

    useEffect(() => {
        if (playlist.isInitialTest) setPlaylistHasInitialTest(true);
    }, []);

    return (
        <div className="chatbot-layout">
            {userType === "STUDENT" ? null : (
                <ChatbotDrawer
                    activity={playlist!.activity}
                    objective={playlist!.objective}
                />
            )}

            <div className="chatbot-core__container">
                <div className="chatbot-core">
                    {showWelcome && (
                        <Welcome
                            onStart={() => setStarted(true)}
                            started={started}
                            welcomeMessages={
                                playlistHasInitialTest
                                    ? [
                                          ...config.i18n.chatbot
                                              .welcomeMessages,
                                          config.i18n.chatbot
                                              .initialTestMessages.start,
                                      ]
                                    : config.i18n.chatbot.welcomeMessages
                            }
                            startButtonText={config.i18n.chatbot.start}
                        />
                    )}
                    <>
                        {playlist!.exerciseResults.map(
                            (exerciseResult, index) => {
                                const isLastExerciseResult =
                                    index ===
                                    playlist!.exerciseResults.length - 1;
                                return (
                                    <ExerciseResult
                                        exercise={getExerciseById(
                                            exerciseResult.exerciseId,
                                            data
                                        )}
                                        exerciseResult={exerciseResult}
                                        key={index}
                                        onGoToNextExercise={
                                            isLastExerciseResult &&
                                            playlist?.currentExecutionStage ===
                                                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
                                                ? () => {
                                                      if (
                                                          config.features.emotionalReport && userType === "STUDENT" &&
                                                          playlist.comingNext !==
                                                              "retry"
                                                      )
                                                          emotionalReportController.recordValidatedExercise();
                                                      onGoToNextExercise();
                                                  }
                                                : undefined
                                        }
                                        comingNext={playlist?.comingNext}
                                    />
                                );
                            }
                        )}
                    </>
                    {started &&
                    playlist?.currentExercise &&
                    playlist?.currentExecutionStage ===
                        PlaylistExecutionStage.PlayingCurrentExercise &&
                    !emotionalReportController.showEmotionalReportPopup ? (
                        <>
                            <CurrentExercise
                                currentExercise={playlist.currentExercise}
                                key={`current-${playlist.currentExercise.id}-${playlist.currentTry}`}
                                onExerciseResult={onExerciseResult}
                            />
                            <div className="current-exercise__background-shader"></div>
                        </>
                    ) : null}
                    {started &&
                        playlist?.currentExecutionStage ===
                            PlaylistExecutionStage.ShowingEndOfInitialTestMessage && (
                            <>
                                <ChatBubble
                                    direction={"left"}
                                    botAvatar="visible"
                                    color="var(--chatbot-color-bot-bubble)"
                                >
                                    {parse(
                                        config.i18n.chatbot.initialTestMessages
                                            .end.$html
                                    )}
                                </ChatBubble>
                                <GoToNext
                                    onGoToNextExercise={() => {
                                        if (
                                            config.features.emotionalReport && userType === "STUDENT" &&
                                            playlist.comingNext !== "retry"
                                        )
                                            emotionalReportController.recordValidatedExercise();
                                        if (clearHistory) clearHistory();
                                        setShowWelcome(false);
                                        setPlaylistHasInitialTest(false);
                                        onGoToNextExercise();
                                    }}
                                    comingNext={playlist.comingNext}
                                />
                            </>
                        )}
                </div>
            </div>

            {config.features.emotionalReport && userType === "STUDENT" && (
                <EmotionalReport {...emotionalReportController} />
            )}
        </div>
    );
};
export default ChatbotShell;
