import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
//
import { colors } from "../utils";
import { configStore } from "../../../../contexts/ConfigContext";
import DemoBanner from "../../../../components/DemoBanner/DemoBanner";
import { dashboardStore } from "../../../../contexts/DashboardContext";
import { getModuleById } from "../../../../utils/dataRetrieval";
import { dataStore } from "../../../../contexts/DataContext";
import {
    ClusterInfosClustering,
    InfosVariables,
    ModuleCluster,
} from "../../../../interfaces/Dashboard";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

const VARIABLES: ["var1", "var2", "var3"] = ["var1", "var2", "var3"];

export default function DetailsCard({
    studentId,
    classroomId,
}: {
    studentId: string;
    classroomId: string;
}) {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);

    const {
        dashboard: { clustering },
    } = useContext(dashboardStore);
    // Take only current classroom and remove modules that have errors in clustering or for which the student has no cluster
    const _clustering: { [moduleId: string]: ModuleCluster } = {};
    Object.keys(clustering![classroomId]).forEach((moduleId) => {
        if (
            typeof clustering![classroomId][moduleId].error !== "undefined" ||
            !findStudentCluster(
                studentId,
                (clustering![classroomId][moduleId] as ModuleCluster)
                    .infosClustering.clusters
            )
        )
            return;

        _clustering[moduleId] = clustering![classroomId][
            moduleId
        ] as ModuleCluster;
    });

    const [currentModuleId, setCurrentModuleId] = useState<string>(
        Object.keys(_clustering)
            .map((moduleId) => {
                return {
                    moduleId,
                    title: getModuleById(moduleId, data).title.short!,
                };
            })
            .sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            })[0].moduleId
    );
    const [studentCluster, setStudentCluster] = useState<
        ClusterInfosClustering | undefined
    >(
        currentModuleId
            ? findStudentCluster(
                  studentId,
                  _clustering[currentModuleId].infosClustering.clusters
              )!
            : undefined
    );

    // If current module id changes, update studentCluster
    useEffect(() => {
        setStudentCluster(
            findStudentCluster(
                studentId,
                _clustering[currentModuleId].infosClustering.clusters
            )!
        );
    }, [currentModuleId]);

    if (!currentModuleId || !studentCluster) return <></>;

    return (
        <Card>
            {config.features.demoMode?.displayIn.clustering ? (
                <DemoBanner
                    position="custom"
                    message={config.features.demoMode.message.clustering}
                />
            ) : null}

            <Center>
                <CustomSelect
                    onSelectOption={(selectedModuleId) => {
                        setCurrentModuleId(selectedModuleId);
                    }}
                    selectedOption={
                        getModuleById(currentModuleId, data).title.short!
                    }
                >
                    {Object.keys(_clustering)
                        .map((moduleId) => {
                            return {
                                moduleId,
                                title: getModuleById(moduleId, data).title
                                    .short!,
                            };
                        })
                        .sort((a, b) => {
                            if (a.title < b.title) {
                                return -1;
                            }
                            if (a.title > b.title) {
                                return 1;
                            }
                            return 0;
                        })
                        .map((moduleInfo) => (
                            <option value={moduleInfo.moduleId}>
                                {moduleInfo.title}
                            </option>
                        ))}
                </CustomSelect>
            </Center>

            <GroupName>{studentCluster.name}</GroupName>

            <VariablesWrapper>
                {VARIABLES.map((variable) => (
                    <>
                        <VariableDescription>
                            {
                                config.i18n.dashboard!.clustering.variables[
                                    getVariable(
                                        variable,
                                        _clustering[currentModuleId]
                                    ).description.type
                                ]
                            }
                        </VariableDescription>
                        <VariableStudentValue>
                            {getStudentValue(
                                studentId,
                                variable,
                                _clustering[currentModuleId]
                            )}
                        </VariableStudentValue>
                    </>
                ))}
            </VariablesWrapper>
        </Card>
    );
}

const findStudentCluster = (
    studentId: string,
    clusters: { [clusterName: string]: ClusterInfosClustering }[]
): ClusterInfosClustering | undefined => {
    const _clusters = clusters
        .map((clustersObj) =>
            Object.keys(clustersObj).map(
                (clusterName) => clustersObj[clusterName]
            )
        )
        .flat();
    return _clusters.find((cluster) => cluster.eleves.includes(studentId));
};

const getVariable = (
    variable: string,
    clustering: ModuleCluster
): InfosVariables & { variableName: "var1" | "var2" | "var3" } => {
    const variables = clustering.infosVariables
        .map((variableObj) =>
            Object.keys(variableObj).map((variableName) => {
                return { ...variableObj[variableName], variableName };
            })
        )
        .flat();
    return variables.find(
        (item) => item.variableName === variable
    )! as InfosVariables & { variableName: "var1" | "var2" | "var3" };
};

const getStudentValue = (
    studentId: string,
    variableName: "var1" | "var2" | "var3",
    clustering: ModuleCluster
): string => {
    const students = clustering.infosEleves
        .map((studentObj) =>
            Object.keys(studentObj).map((studentId) => studentObj[studentId])
        )
        .flat();
    const student = students.find((student) => student.id === studentId)!;
    const variable = getVariable(variableName, clustering);
    return `${student[variableName]} ${variable.unit}`;
};

const Card = styled.div`
    background: ${colors.lightGrey};
    padding: 16px 24px;
    border-radius: 8px;
    width: calc(100% - 48px);
    height: calc(100% - 68px);
`;

const GroupName = styled.h4`
    text-align: center;
    font-size: 1.5em;
    margin: 16px 0;
`;

const VariablesWrapper = styled.div`
    padding: 16px;
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: 1fr max-content;
`;

const VariableDescription = styled.div`
    font-weight: 700;
    flex: 1;
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    border-right: 1px solid ${colors.lightGrey};
    border-radius: 16px 0 0 16px;
`;

const VariableStudentValue = styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 0 16px 16px 0;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
`;
