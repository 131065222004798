import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../interfaces/Config";
import "./Footer.scss";
import { getLabel, getUrl } from "../../utils/dataRetrieval";
import { sessionStore } from "../../contexts/SessionContext";
import UserDataDownloadButton from "./UserDataDownloadButton/UserDataDownloadButtons";
import { configStore } from "../../contexts/ConfigContext";

interface FooterProps {
    logos: {
        footer_client: string;
        footer_evidenceb: string;
    };
    pages: Page[];
    contact: string;
    i18n: {
        contact: string;
        developedBy: string;
    };
}

/**
 * Main footer of the Athena app
 * 
 * Available features:
 * - user data download button (with the userDataDownload flag)
 */
function Footer({ logos, pages, contact, i18n }: FooterProps) {
    /*     const goToContact = () => {
        window.open(contact,'_blank')
    } */
    
    const { session: { userType } } = useContext(sessionStore);
    const { config } = useContext(configStore);
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer">
            <div className="container">
                <img className="logo left" src={logos.footer_client} />
                <div className="links">
                    <nav>
                        {pages.map((page, i) =>
                            page.inFooter ? (
                                <li key={`footer-link-${i}`}>
                                    <Link to={`/${getUrl(page, userType)}`}>{getLabel(page, userType)}</Link>
                                </li>
                            ) : null
                        )}
                    </nav>

                    <div className="footer__buttons-container">
                        {config.features.userDataDownload && <UserDataDownloadButton />}

                        <a href={contact} target="_blank">
                            <span className="material-icons">mail_outline</span>
                            <span>{i18n.contact}</span>
                        </a>
                    </div>
                    {/*  <Button style={"tertiary"} label={i18n.contact} icon={"mail_outline"} onClick={goToContact}/> */}
                </div>
                <div className="logo right">
                    <span>{i18n.developedBy}</span>
                    <img src={logos.footer_evidenceb} />
                </div>
            </div>
            <div className="copyright">© Copyright {currentYear} EvidenceB</div>
        </div>
    );
}

export default Footer;
