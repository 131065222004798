import React, { useEffect, useContext, useState } from "react";
import ProgressBarLink from "./ProgressBarLink/ProgressBarLink";
import Tabs, { TabPane } from "rc-tabs";
import { Dashboard, ModuleCluster } from "../../../interfaces/Dashboard";
import { useRouteMatch } from "react-router-dom";
import TabLink from "./TabLink/TabLink";
import "./ClassList.scss";
import "rc-tabs/assets/index.css";
import "rc-tooltip/assets/bootstrap_white.css";
import Dialog from "rc-dialog";
import "rc-dialog/assets/index.css";
import { dataStore } from "../../../contexts/DataContext";
import { configStore } from "../../../contexts/ConfigContext";
import { dashboardStore } from "../../../contexts/DashboardContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { getClusters, getModuleById } from "../../../utils/dataRetrieval";
import { fetchData, getData } from "../../../utils/request-data";
import { customAlphabet, urlAlphabet } from 'nanoid'
import { Classroom } from "../../../interfaces/User";
import Loader from "../../../components/Loader/Loader";
import * as localStorage from '../../../utils/localStorage';
interface RegisterState {
  step: 'register' | 'saving' | 'saved';
  classCode?: string;
}

const ClassList: React.FC<Dashboard> = ({ classrooms }) => {
  let { url } = useRouteMatch();
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);
  const { session } = useContext(sessionStore);
  const { dashboard: { clustering }, setDashboard } = useContext(dashboardStore);
  const [open, setOpen] = React.useState(false);
  const [registerClassState, setRegisterClassState] = useState<RegisterState>({ step: 'register' })

  const onModalClose = () => {
    setOpen(false);
    setRegisterClassState({ step: "register", classCode: undefined })
  };

  const onOpenModal = () => {
    setOpen(true);
  };

  const onSubmit = async (text: string) => {

    setRegisterClassState({ step: "saving" })

    let postHeaders = new Headers({
      "Authorization": 'Bearer ' + localStorage.getItem<string>(localStorage.Key.TOKEN),
      "Content-Type": "application/json"
    })
    let getHeaders = new Headers({
      "Authorization": 'Bearer ' + localStorage.getItem<string>(localStorage.Key.TOKEN),
      "x-evb-origin": window.location.origin,
      "version": config.declinaison
    })

    const nanoid = customAlphabet(urlAlphabet, 10)
    let body = {
      external_id: nanoid(),
      provider: config.declinaison,
      name: text,
      level: 'none',
      school: '',
      teachers: [session.userId],
      students: [],
      extra: 'none'
    }
    let createClass = await fetchData<Classroom>(config.msConfigs?.endpoints.users + '/api/classrooms/', 'POST', body, postHeaders)
    console.log("API: CLASSROOMS: POST", createClass);
    const queryParams = config.features.clustering ? "" : "?enableclustering=false";
    const dashboard = await getData<any>(config.msConfigs?.endpoints.analytics + "/teacher/" + session.userId + "/classrooms_analytics/" + queryParams, getHeaders)
    setDashboard(dashboard)
    setRegisterClassState({ step: "saved", classCode: body.external_id })
  };

  return (
    <div className="classrooms-list-container">
      <h1>{config.i18n.dashboard?.common.classes}</h1>
      <div className="classrooms-list">
        {classrooms.map((classroom, i) => (
          <Tabs defaultActiveKey="1" className="classrooms-tabs" key={classroom.id} >

            <TabPane
              tab={
                <TabLink
                  link={`${url}/${config.i18n.dashboard?.paths.classes}/${classroom.id}`}
                  name={classroom.name}
                  isAlert={classroom.alert.isAlert}
                  alertMessage={classroom.alert.isAlert ? config.i18n.dashboard?.classList.alertInfo : ""}
                />
              }
              key={`groupe-tab-${classroom.id}`}
              className="classrooms-tab"
            >
              {config.features.classCode !== false && <div className="classCode">{config.i18n.dashboard?.classDetails.code} {classroom.external_id}</div>}
              <div className="progress-bar-links">
                {
                  classroom.modulesList.length ? classroom.modulesList.map((module, i) => (
                    <ProgressBarLink
                      url={`${url}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroom.id}/${module.id}`}
                      progression={(module.progression*100).toFixed(1)}
                      title={`${getModuleById(module.id, data).title.short}`}
                      key={module.id}
                    />
                  )) : <div className="no-data">{config.i18n.dashboard?.common.noStudentsData}</div>
                }
              </div>
            </TabPane>
            {
              config.features.clustering && clustering ? 
                <TabPane tab={`${config.i18n.dashboard?.common.groups}`} key={`groupe-tab`}>
                  {
                    classroom.modulesList.length ? classroom.modulesList.map((module) => (
                      <ProgressBarLink
                        url={`${url}/${config.i18n.dashboard?.paths.classes}/${classroom.id}/${module.id}/${config.i18n.dashboard?.paths.groups}`}
                        progression={(module.progression*100).toFixed(1)}
                        title={getModuleById(module.id, data).title.short}
                        key={module.id}
                        displayProgressionLabel={false}
                        group={typeof clustering[classroom.id][module.id] !== "undefined" && typeof clustering[classroom.id][module.id].error === "undefined" ? getClusters(clustering[classroom.id][module.id] as ModuleCluster).length : undefined}
                      />
                    )) : <div className="no-data">{config.i18n.dashboard?.common.noStudentsData}</div>
                  }
                </TabPane>
                : null
            }    

          </Tabs>
        ))}

        {
          config.features.classManagement ?
            <div className="create-class" onClick={onOpenModal}>
              <span className="material-icons add-icon">add_circle_outline</span>
              <span className="message">{config.i18n.dashboard?.classList.form.add}</span>
            </div>
            : null
        }

        <Dialog
          closable={registerClassState.step === "saving" ? false : true}
          maskClosable={registerClassState.step === "saving" ? false : true}
          title={
            <div className="modal-title">
              <>
                {
                  registerClassState.step === "register" ?
                    <span className="material-icons add-icon">add_circle_outline</span> :
                    registerClassState.step === "saved" ?
                      <span className="material-icons success-icon">check_circle</span> :
                      null
                }
              </>
              <span className="title">{
                registerClassState.step === "saving" ?
                  <span>
                    {config.i18n.dashboard?.classList.form.saving}
                  </span> :
                  registerClassState.step === "saved" ?
                    <span>
                      {config.i18n.dashboard?.classList.form.success}
                    </span> :
                    config.i18n.dashboard?.classList.form.new
              }</span>
            </div>
          }
          visible={open}
          onClose={onModalClose}
        >
          <NewClass onCancel={onModalClose} onSubmit={onSubmit} state={registerClassState} />
        </Dialog>
      </div>
    </div>
  );
};

const NewClass: React.FC<{
  onCancel: () => void;
  onSubmit: (value: string) => void;
  state: RegisterState;
}> = ({ onCancel, onSubmit, state }) => {
  const { config } = useContext(configStore);
  const [textValue, setTextValue] = React.useState("");

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTextValue(event.currentTarget.value);
  };

  const goNextStep = () => {
    onSubmit(textValue)
  }

  useEffect(() => {
    console.log("state", state);

  }, [state])

  return (
    <div className="new-class-container">
      {
        state.step === "register" ?
          <div className="step step-1">
            <span className="label">{config.i18n.dashboard?.classList.form.name}</span>
            <input
              className="input"
              name="name"
              onChange={onInputChange}
              type="text"
            />
            <div className="buttons">
              <div className="add" onClick={goNextStep}>
                {config.i18n.dashboard?.classList.form.add}
              </div>
              <span className="cancel" onClick={onCancel}>
                {config.i18n.dashboard?.classList.form.cancel}
              </span>
            </div>
          </div> :
          state.step === "saving" ? <div className="step step-2"><Loader /></div> :
            state.step === "saved" ?
              <div className="step step-3">
                <div className="message">
                  <span className="label">{config.i18n.dashboard?.classList.form.code}</span>
                  <span className="code">{state.classCode}</span>
                  <p>{config.i18n.dashboard?.classList.form.message}</p>
                </div>
                <div className="buttons">
                  <div className="add" onClick={onCancel}>
                    {config.i18n.dashboard?.classList.form.continue}
                  </div>
                </div>
              </div> : null
      }


    </div>
  );
};

export default ClassList;
