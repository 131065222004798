import { useContext, useEffect, useState } from "react";
import BoredBot from "./bored-bot";
import ConfusedBot from "./confused-bot";
import FrustratedBot from "./frustrated-bot";
import NeutralBot from "./neutral-bot";
import { configStore } from "../../contexts/ConfigContext";
import { sessionStore } from "../../contexts/SessionContext";
import { makeEmotionalReportStatement } from "../../utils/tracking";
import * as localStorage from "../../utils/localStorage";
import * as Sentry from "@sentry/react";
import { getContext } from "../../utils/sentry";

export enum Emotion {
    Frustrated = "FRUSTRATED",
    Confused = "CONFUSED",
    Bored = "BORED",
    Neutral = "NEUTRAL",
}

export interface EmotionalReportController {
    showEmotionalReportPopup: boolean;
    /**
     * TEMP: This is an executive decision made by Anaelle while Lea is on
     * holidays. This tries to solve the problem of responsivity: removing the
     * speech bubbles means taking out context necessary for the user to
     * unederstand what they are using. But context is available in the popup
     * whatever the screen size, so the first time the user uses the emotional
     * report should be with the popup, and then they can better understand the
     * widget even without the speech bubbles.
     */
    showEmotionalReportWidget: boolean;
    clickedButtons: Emotion[] | null;
    recordReport: (
        reportedEmotions: Emotion,
        reportType: "FORCED" | "SELF-REPORT"
    ) => void;
    recordValidatedExercise: () => void;
}

export const EMOTIONS: {
    [key in Emotion]: {
        i18nKey: "frustrated" | "neutral" | "confused" | "bored";
        Component: () => JSX.Element;
    };
} = {
    [Emotion.Frustrated]: {
        Component: FrustratedBot,
        i18nKey: "frustrated",
    },
    [Emotion.Bored]: {
        Component: BoredBot,
        i18nKey: "bored",
    },
    [Emotion.Confused]: {
        Component: ConfusedBot,
        i18nKey: "confused",
    },
    [Emotion.Neutral]: {
        Component: NeutralBot,
        i18nKey: "neutral",
    },
};

const NUMBER_OF_EXERCISES_BEFORE_FORCED_REPORT = 3;
const DURATION_OF_EMOTIONAL_REPORT_UI_FEEDBACK = 3000;

const useEmotionalReport = (): EmotionalReportController => {
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const [clickedButtons, setClickedButtons] = useState<Emotion[] | null>(
        null
    );
    const [exercisesSinceLastReport, setExercisesSinceLastReport] = useState(0);
    const [showEmotionalReportWidget, setEmotionalReportWidget] =
        useState<boolean>(false);

    // TEMP cf higher
    // Activate the widget once the popup has been triggered at least once
    useEffect(() => {
        if (showEmotionalReportWidget) return;
        if (exercisesSinceLastReport > NUMBER_OF_EXERCISES_BEFORE_FORCED_REPORT)
            setEmotionalReportWidget(true);
    }, [showEmotionalReportWidget, exercisesSinceLastReport]);

    return {
        showEmotionalReportPopup:
            exercisesSinceLastReport > NUMBER_OF_EXERCISES_BEFORE_FORCED_REPORT,

        showEmotionalReportWidget,

        clickedButtons,

        recordReport(clickedButton, reportType) {
            // Send statement to LRS
            const statement = makeEmotionalReportStatement(
                config.declinaison,
                session.evidencebId,
                clickedButton,
                session.sessionId,
                reportType
            );
            try {
                fetch(config.msConfigs!.endpoints.statements!, {
                    method: "post",
                    headers: new Headers({
                        Authorization:
                            "Bearer " +
                            localStorage.getItem<string>(localStorage.Key.TOKEN),
                        "x-evidenceb-app": "athena",
                        "Content-Type": "application/json",
                    }),
                    body: JSON.stringify(statement),
                });
            } catch (err) {
                // [TODO] Error andling
                Sentry.captureException(
                    err,
                    getContext()
                );
            }

            setClickedButtons((curr) => {
                if (!curr) return [clickedButton];
                if (curr.includes(clickedButton)) return curr;
                return [...curr, clickedButton];
            });
            setTimeout(() => {
                setClickedButtons((curr) => {
                    if (!curr)
                        throw new Error("Problem in useEmotionalReport logic");
                    return curr.filter((emotion) => emotion !== clickedButton);
                });
            }, DURATION_OF_EMOTIONAL_REPORT_UI_FEEDBACK);
            setExercisesSinceLastReport(0);
        },
        
        recordValidatedExercise() {
            setExercisesSinceLastReport(exercisesSinceLastReport + 1);
        },
    };
};

export default useEmotionalReport;
