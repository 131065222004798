import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { configStore } from "../../../../contexts/ConfigContext";
import "./ProgressBarLink.scss";
type ProgressBarLinkProps = {
    url: string;
    progression: number | string;
    title: string | undefined;
    displayProgressionLabel?: boolean;
    group?: number;
};

const ProgressBarLink: React.FC<ProgressBarLinkProps> = ({
    url,
    progression,
    title,
    displayProgressionLabel = true,
    group,
}) => {
    const { config } = useContext(configStore);

    return (
        <ConditionalLink
            url={url}
            condition={displayProgressionLabel || typeof group !== "undefined"}
        >
            <div className="progress-bar-text">
                <div className="module-title">{title}</div>
                {displayProgressionLabel && typeof group === "undefined" ? (
                    <div className="module-progression">{`${Math.round(
                        +progression
                    )} %`}</div>
                ) : typeof group !== "undefined" ? (
                    <div className="group">{`${group} ${config.i18n.dashboard?.classList.identifiedGroups}`}</div>
                ) : (
                    <div aria-hidden="true"></div>
                )}
            </div>

            <div
                className="progressBar"
                style={{ width: progression + "%" }}
            ></div>
        </ConditionalLink>
    );
};

const ConditionalLink: React.FC<{
    condition: boolean;
    url: string;
}> = ({ condition, url, children }) => {
    if (condition)
        return (
            <Link to={url} className="progress-bar-link">
                {children}
            </Link>
        );
    return <div className="progress-bar-link">{children}</div>;
};

export default ProgressBarLink;
