const APP_CONFIG = (window as any).APP_CONFIG ?? {};

interface Config {
    configJsonUrl?: string;
    dataJsonUrl?: string;
}
const config: Config = {
    configJsonUrl: APP_CONFIG.CONFIG_JSON_URL,
    dataJsonUrl: APP_CONFIG.DATA_JSON_URL,
};
export default config;
