import React, { useState, useEffect, useContext, MouseEvent } from 'react';
import { Link, useParams, useHistory } from "react-router-dom";
// Components
import StudentIcon from "./components/StudentIcon";
import TimeSpentChart from "./components/TimeSpentChart";
import GroupCard from "./components/GroupCard";
import Progress from "./components/Progress";
// Contexts
import { dashboardStore } from "../../../contexts/DashboardContext";
import { configStore } from "../../../contexts/ConfigContext";
// Interfaces
import { ModuleDashboard, Progression, Student } from "../../../interfaces/Dashboard";
//
import "./scss/StudentDetails.scss";
import StudentProgression, { ProgressionData } from '../StudentProgression/StudentProgression';

interface ParamTypes {
  classroomId: string;
  studentId: string;
}

type ProgressFilter = string;

export default function StudentDetails() {

  const [studentData, setStudentData] = useState<Student>();
  const [studentModules, setStudentModules] = useState<ModuleDashboard[]>();
  const [progressFilter, setProgressFilter] = useState<ProgressFilter>();
  const { classroomId, studentId } = useParams<ParamTypes>();
  const { dashboard } = useContext(dashboardStore);
  const { config } = useContext(configStore);
  const history = useHistory();

  const [progression, setProgression] = useState<ProgressionData | undefined>()

  useEffect(() => {
    const classroom = dashboard.classrooms.find((el) => (
      classroomId === el.id
    ));

    const student = classroom?.modulesList[0].students[studentId];
    const modules = classroom?.modulesList.filter((module) => (
      module.students[studentId]?.id === studentId
    ));
    
    setStudentModules(modules);
    
    setStudentData(student);

  }, [dashboard, classroomId, studentId]);

  const selectHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.currentTarget.parentElement?.classList.toggle('show')
  }
  const filterHandler = (e: MouseEvent<HTMLLIElement>) => {
    e.currentTarget.parentElement?.parentElement?.classList.toggle('show');
    // filter something
    setProgressFilter(e.currentTarget.dataset.value);
  }
  const resetFilter = (e: MouseEvent<HTMLButtonElement>) => {
    setProgressFilter(undefined);
  }

  return (
    <>
      <div id="student-details-container">
        <div className="back-btn">
          <button onClick={() => history.goBack()}>
            <span className="material-icons"> arrow_back </span>
          </button>
        </div>
        <div id="student-name-container">
          <StudentIcon />
          <h1 id="student-name"><span>{`${studentData?.firstname} ${studentData?.lastname}`}</span><span>{config.i18n.dashboard?.studentDetails.lastLogin} </span></h1>
        </div>
        <div id="card-container">
          <div className="top-cards">

            <div className={`card medium ${!config.features.clustering ? 'large' : ''}`}>
              <h2 className="card-title">{config.i18n.dashboard?.studentDetails.timePassedOnRessources}</h2>
              <TimeSpentChart  studentId={studentId} studentModules={studentModules} />
            </div>

            {
              config.features.clustering ? 
                <div className="card small">
                  <h2 className="card-title">{config.i18n.dashboard?.common.groups}</h2>
                  <GroupCard classroomId={classroomId} studentId={studentId} />
                </div>
                : null
            }
          </div>

          <div className="card large">
            <h2 className="card-title">{config.i18n.dashboard?.common.progression}</h2>

            {/* [TODO] This should be a <select> */}
            <div className="details-custom-select filters">
              {
                progressFilter ? 
                  <button onClick={resetFilter}>X</button>
                : null
              }
              <div className="label" onClick={selectHandler}>
                <span className="text">{config.i18n.dashboard?.studentDetails.filterDefaultValue}</span>
                <span className="material-icons icon open"> expand_more </span>
                <span className="material-icons icon close"> expand_less </span>
              </div>
              <ul className="select">
                <li onClick={filterHandler} data-value={'difficulties'} className="option difficulties">
                  <span className="icon"></span>
                  <span className="label">{config.i18n.dashboard?.studentDetails.fileters.difficulties}</span>
                </li>
                <li onClick={filterHandler} data-value={'facilities'} className="option facilities">
                  <span className="icon"></span>
                  <span className="label">{config.i18n.dashboard?.studentDetails.fileters.facilities}</span>
                </li>
                <li onClick={filterHandler} data-value={'validatedObjectives'} className="option validatedObjectives">
                  <span className="icon"></span>
                  <span className="label">{config.i18n.dashboard?.studentDetails.fileters.validatedObjectives}</span>
                </li>
                <li onClick={filterHandler} data-value={'currentObjective'} className="option currentObjective">
                  <span className="icon"></span>
                  <span className="label">{config.i18n.dashboard?.studentDetails.fileters.currentObjectives}</span>
                </li>
              </ul>
            </div>

            <Progress progressFilter={progressFilter} modules={studentModules} setProgression={setProgression} />
          </div>
        </div>
        {
          progression ? <StudentProgression data={progression} setProgression={setProgression} /> : null
        }
      </div>
    </>
  )
}