import React from "react";
import { ClusterInfosClustering } from "../../../interfaces/Dashboard";

import "./ClustersVisualRepresentation.scss";

type StudentCirclesProps = {
    selectedGroupIndex?: number;
    setSelectedGroupIndex: (index?: number) => void;
    clusters: ClusterInfosClustering[];
    selectedStudentId?: string;
};

const ClustersVisualRepresentation: React.FC<StudentCirclesProps> = ({
    clusters,
    selectedGroupIndex,
    setSelectedGroupIndex,
    selectedStudentId,
}) => {
    return (
        <div className="circles-container">
            {clusters.map((cluster, clusterIndex) => (
                <div
                    key={"circles" + clusterIndex}
                    className={`circle circle-border-${clusterIndex + 1} ${
                        selectedGroupIndex === clusterIndex ? "shadow" : ""
                    }`}
                    onClick={() => {
                        setSelectedGroupIndex(
                            clusterIndex === selectedGroupIndex
                                ? undefined
                                : clusterIndex
                        );
                    }}
                >
                    {cluster.eleves.map(student => {
                        return (
                            <span
                                className={`material-icons student student-cluster-${clusterIndex + 1} ${
                                    student === selectedStudentId
                                        ? "selected"
                                        : ""
                                }`}
                                key={student}
                            >
                                account_circle
                            </span>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

export default ClustersVisualRepresentation;
