import React, { MouseEvent } from "react";
import Button from "../Button/Button";
import "./CardEntry.scss";
import { Link } from "react-router-dom";
interface Entry {
    img: string;
    title: string;
    desc: string;
    buttonLabel: string;
    url: string;
}

const CardEntry = ({ img, title, desc, buttonLabel, url }: Entry) => {
    return (
        <div className="card-entry">
            <Link to={`/${url}`}>
                <div className="img">
                    <img src={img} />
                </div>
                <div className="content">
                    <h2 className="roboto">{title}</h2>
                    {desc ? <p>{desc}</p> : null}
                    <Button
                        style={"primary"}
                        label={buttonLabel}
                        icon={"arrow_forward"}
                    />
                </div>
            </Link>
        </div>
    );
};

export default CardEntry;
