import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link, useRouteMatch } from "react-router-dom";
import { dataStore } from "../../contexts/DataContext";
import { configStore } from "../../contexts/ConfigContext";
import {
    Classroom,
    Dashboard,
    InfosEleves,
    InfosVariables,
    ModuleCluster,
    ModuleDashboard,
    ParamTypes,
} from "../../interfaces/Dashboard";
import GroupCharacteristics from "./GroupCharacteristics/GroupCharacteristics";
import ClustersVisualRepresentation from "./ClustersVisualRepresentation/ClustersVisualRepresentation";
import Group from "./Group/Group";
import {
    getClusters,
    getModuleById,
    getStudentName,
} from "../../utils/dataRetrieval";

import "./Clustering.scss";

const Clustering: React.FC<{
    classrooms: Dashboard["classrooms"];
    clustering: Required<Dashboard>["clustering"];
}> = ({ classrooms, clustering }) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const history = useHistory();
    const { path } = useRouteMatch();
    const { classroomId, moduleId } = useParams<ParamTypes>();

    const [currentClassroom, setCurrentClassroom] = useState<Classroom>();
    const [currentModule, setCurrentModule] = useState<ModuleDashboard>();
    const [selectedGroupIndex, setSelectedGroupIndex] =
        React.useState<number>();
    const [selectedStudentId, setSelectedStudentId] = React.useState<string>();

    // Set classroom
    useEffect(() => {
        setCurrentClassroom(
            classrooms.find(
                (classroom) => classroom.id === classroomId
            ) as Classroom
        );
    }, [classroomId]);

    // Set module
    useEffect(() => {
        if (!currentClassroom) return;

        setCurrentModule(
            currentClassroom.modulesList.find(
                (module) => module.id === moduleId
            )!
        );
    }, [currentClassroom, moduleId]);

    // Reinit selected student when selected group changes
    useEffect(() => {
        setSelectedStudentId(undefined);
    }, [selectedGroupIndex, classroomId, moduleId]);

    return (
        <div className="classroom-modules-overview">
            <header>
                <div className="breadcrumbs">
                    <ul>
                        <li>
                            <Link
                                to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}`}
                            >
                                {config.i18n.dashboard?.common.classes}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${classroomId}`}
                            >
                                {currentClassroom?.name}
                            </Link>
                        </li>
                        <li>{config.i18n.dashboard?.common.groups}</li>
                    </ul>
                </div>
            </header>

            <div className="group-body">
                <div className="classroom-navigation">
                    <h1>{config.i18n.dashboard?.common.groups}</h1>

                    <select
                        onChange={(e) => {
                            history.push(
                                path
                                    .replace(/:classroomId/, e.target.value)
                                    .replace(/:moduleId/, moduleId)
                            );
                        }}
                        value={currentClassroom ? currentClassroom?.id : "0"}
                        className="select"
                    >
                        {classrooms.map((classroom, i) => (
                            <option
                                key={"class-" + i}
                                value={classroom.id}
                            >{`Classe ${classroom.name}`}</option>
                        ))}
                    </select>

                    {currentClassroom && currentClassroom.modulesList.length > 0 && (
                        <select
                            onChange={(e) => {
                                history.push(
                                    path
                                        .replace(/:moduleId/, e.target.value)
                                        .replace(/:classroomId/, classroomId)
                                );
                            }}
                            value={currentModule?.id}
                            className="select"
                        >
                            {currentClassroom.modulesList.map((module, i) => (
                                <option key={"module-" + i} value={module.id}>
                                    {getModuleById(module.id, data).title.short}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="clusters-container">
                    {typeof clustering[classroomId][moduleId] ===
                    "undefined" ? (
                        config.i18n.dashboard!.clustering.noClustersForClassAndModudle
                    ) : typeof clustering[classroomId][moduleId].error !==
                      "undefined" ? (
                        config.i18n.dashboard!.clustering.noClustersForClassAndModudle
                    ) : (
                        <>
                            <div className="clusters__right">
                                <h2>
                                    {
                                        config.i18n.dashboard?.clustering
                                            .characteristics
                                    }
                                </h2>

                                <div className="characteristics-container">
                                    {currentModule &&
                                        getClusters(
                                            clustering[classroomId][
                                                moduleId
                                            ] as ModuleCluster
                                        ).map((cluster, index) => {
                                            return (
                                                <GroupCharacteristics
                                                    key={
                                                        "GroupCharacteristics-" +
                                                        index
                                                    }
                                                    variables={getVariables(
                                                        clustering[classroomId][
                                                            moduleId
                                                        ] as ModuleCluster
                                                    )}
                                                    cluster={cluster}
                                                    groupIndex={index}
                                                    selected={
                                                        index ===
                                                        selectedGroupIndex
                                                    }
                                                    onSelectGroup={() => {
                                                        if (
                                                            index ===
                                                            selectedGroupIndex
                                                        )
                                                            setSelectedGroupIndex(
                                                                undefined
                                                            );
                                                        else
                                                            setSelectedGroupIndex(
                                                                index
                                                            );
                                                    }}
                                                    selectedStudent={
                                                        selectedStudentId
                                                            ? getStudent(
                                                                  clustering[
                                                                      classroomId
                                                                  ][
                                                                      moduleId
                                                                  ] as ModuleCluster,
                                                                  selectedStudentId
                                                              )
                                                            : undefined
                                                    }
                                                />
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="clusters__left">
                                <ClustersVisualRepresentation
                                    clusters={getClusters(
                                        clustering[classroomId][
                                            moduleId
                                        ] as ModuleCluster
                                    )}
                                    selectedGroupIndex={selectedGroupIndex}
                                    setSelectedGroupIndex={
                                        setSelectedGroupIndex
                                    }
                                    selectedStudentId={selectedStudentId}
                                />

                                <div className="group-container">
                                    {getClusters(
                                        clustering[classroomId][
                                            moduleId
                                        ] as ModuleCluster
                                    ).map((cluster, index) => (
                                        <Group
                                            key={"GroupContainer-" + index}
                                            groupeName={cluster.name}
                                            students={cluster.eleves.map(
                                                (studentId) => {
                                                    return {
                                                        id: studentId,
                                                        name: getStudentName(
                                                            classrooms,
                                                            classroomId,
                                                            moduleId,
                                                            studentId
                                                        ),
                                                    };
                                                }
                                            )}
                                            groupIndex={index}
                                            selected={
                                                index === selectedGroupIndex
                                            }
                                            onSelectGroup={() => {
                                                if (
                                                    index === selectedGroupIndex
                                                )
                                                    setSelectedGroupIndex(
                                                        undefined
                                                    );
                                                else
                                                    setSelectedGroupIndex(
                                                        index
                                                    );
                                            }}
                                            selectedStudentId={
                                                selectedStudentId
                                            }
                                            setSelectedStudentId={
                                                setSelectedStudentId
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const getVariables = (
    clustering: ModuleCluster
): (InfosVariables & { name: string })[] => {
    return clustering.infosVariables
        .map((variables) =>
            Object.keys(variables).map((variableName) => {
                return {
                    ...variables[variableName],
                    name: variableName,
                };
            })
        )
        .flat();
};

const getStudent = (
    clustering: ModuleCluster,
    studentId: string
): InfosEleves => {
    return clustering.infosEleves.reduce((studentsObject, studentObject) => {
        Object.keys(studentObject).forEach((key) => {
            const studentId = studentObject[key].id;
            studentsObject[studentId] = studentObject[key];
        });
        return studentsObject;
    }, {} as { [studentId: string]: InfosEleves })[studentId];
};

export default Clustering;
