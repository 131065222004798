import React, { useContext, useEffect, useRef, useState } from "react";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { getCurrentAgent } from "../../../utils/tracking";
import * as localStorage from "../../../utils/localStorage";
import * as Sentry from "@sentry/react";

// Reference for the method used to download a json generated in the frontend : https://javascript.plainenglish.io/how-to-create-download-and-upload-files-in-react-apps-80893da4247a

enum State {
    inactive = "inactive",
    loading = "loading",
    error = "error",
}

const STATES_ICONS: { [key in State]: string } = {
    inactive: "arrow_circle_down",
    loading: "hourglass_empty",
    error: "cancel",
};

/**
 * Button that allows to download all the user data in json format
 */
const UserDataDownloadButton: React.FC = () => {
    const {
        session: { userType, evidencebId },
    } = useContext(sessionStore);
    const {
        config: { declinaison, msConfigs, i18n },
    } = useContext(configStore);
    const [state, setState] = useState<State>(State.inactive);
    const [fileDownloadURL, setFileDownloadURL] = useState<string>();
    const hiddenLink = useRef<HTMLAnchorElement>(null);

    const token = localStorage.getItem<string>(localStorage.Key.TOKEN);
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    });
    const agent = getCurrentAgent(declinaison, evidencebId);

    // When the file download link is set, trigger the download and the end of the download process
    useEffect(() => {
        if (!fileDownloadURL || hiddenLink.current === null) return;

        hiddenLink.current.click();

        URL.revokeObjectURL(fileDownloadURL);
        setFileDownloadURL(undefined);
    }, [fileDownloadURL, hiddenLink]);

    return (
        <>
            <button
                className="user-data-download-button"
                disabled={state !== State.inactive}
                onClick={async () => {
                    if (state !== State.inactive) return;

                    setState(State.loading);

                    const promises = [];
                    promises.push(
                        fetch(msConfigs!.endpoints.users + "/user-info/", {
                            method: "GET",
                            headers: headers,
                            cache: "no-cache",
                        })
                    );
                    if (userType === "STUDENT")
                        promises.push(
                            fetch(
                                msConfigs!.endpoints.statements! +
                                    "?agent=" +
                                    encodeURIComponent(JSON.stringify(agent)),
                                {
                                    method: "get",
                                    headers: new Headers({
                                        Authorization:
                                            "Bearer " +
                                            localStorage.getItem<string>(
                                                localStorage.Key.TOKEN
                                            ),
                                        "x-evidenceb-app": "athena",
                                    }),
                                }
                            )
                        );

                    const requestResults = await Promise.allSettled(promises);
                    if (
                        requestResults.some(
                            (result) => result.status === "rejected"
                        )
                    ) {
                        // [TODO] Error handling
                        Sentry.captureException(
                            requestResults
                                .filter((res) => res.status === "rejected")
                                .map(
                                    (res) =>
                                        (res as PromiseRejectedResult).reason
                                )
                        );
                        setState(State.error);
                        setTimeout(() => {
                            setState(State.inactive);
                        }, 3000);
                        return;
                    }
                    const userData: { "user-info": any; statements?: any } = {
                        "user-info": await (
                            requestResults[0] as PromiseFulfilledResult<any>
                        ).value.json(),
                    };
                    if (userType === "STUDENT")
                        userData["statements"] = await (
                            requestResults[1] as PromiseFulfilledResult<any>
                        ).value.json();

                    const json = JSON.stringify(userData);
                    const blob = new Blob([json], { type: "application/json" });
                    setFileDownloadURL(URL.createObjectURL(blob));

                    // The useEffect picks up the rest of the download process
                }}
            >
                <span className="material-icons">{STATES_ICONS[state]}</span>
                <span>{i18n.misc.userDataDownload}</span>
            </button>

            {fileDownloadURL && (
                <a
                    href={fileDownloadURL}
                    ref={hiddenLink}
                    style={{ display: "none" }}
                    aria-hidden={true}
                ></a>
            )}
        </>
    );
};

export default UserDataDownloadButton;
