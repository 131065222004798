import React, { useEffect, useContext, Dispatch, SetStateAction, useState } from 'react'
import { Link } from "react-router-dom";
import moment from 'moment';
import SimpleBar from 'simplebar-react';

import DemoBanner from '../../../components/DemoBanner/DemoBanner';

import { dataStore } from "../../../contexts/DataContext";
import { configStore } from "../../../contexts/ConfigContext";

import { getActivityById, getObjectiveById } from "../../../utils/dataRetrieval"
import { parse } from '../../../utils/parse-text';

import { Student } from '../../../interfaces/Dashboard';
import { Activity } from "../../../interfaces/Dashboard";
import { Progression } from "../../../interfaces/Dashboard";

import './StudentProgression.scss'
import 'simplebar/dist/simplebar.min.css';

export interface ProgressionData {
    classId: string | undefined;
    objectiveId: string;
    objectiveStatus: "completed" | "inProgress";
    student: Student;
    progression: Progression;
    timeSpent: number;
}

export interface ProgressionProps {
    data: ProgressionData
    setProgression: Dispatch<SetStateAction< ProgressionData | undefined>>
}

function StudentProgression(props: ProgressionProps) {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const [ maximumSuccess, setMaximumSuccess ] = useState<number>(1);

    useEffect(() => {
        console.log("Progression", props.data.progression);

        // Calculate maximum success to have most appropriate bar height
        setMaximumSuccess(
            props.data.progression.activities.reduce((max, activity) => {
                const activityMax = activity.exercises.reduce((activityMax, exercise) => {
                    if (exercise.success.current >= exercise.success.expected && exercise.success.current >= activityMax)
                        return exercise.success.current;
                    if (exercise.success.expected >= exercise.success.current && exercise.success.expected >= activityMax)
                        return exercise.success.expected;
                    return activityMax;
                }, 0);
                return activityMax > max ? activityMax : max;
            }, 0)
        );
    }, [])

    return (
        <div className="progression-container">
    
            <div className="student-progression">
                { config.features.demoMode?.displayIn.studentProgression ? <DemoBanner position={'top'} message={config.features.demoMode?.message.studentProgression} /> : null }
                <div className="container">
                    <div className="name-container">
                        <button>
                            <Link to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.student}/${props.data.classId}/${props.data.student.id}`}>
                                <div className="name">
                                    <span className="material-icons icon">person</span>
                                    <span> {props.data.student.firstname} {props.data.student.lastname}</span>
                                </div>
                                <span className="link">
                                    {config.i18n.dashboard?.common.seeProfile}
                                </span>
                            </Link>
                        </button>
                    </div>
                    <div className="summary">
                        <p><strong>{config.i18n.dashboard?.studentProgression.objectivePath}</strong> {parse( getObjectiveById(props.data.objectiveId, data, true).description.$html )}</p>
                        <p>
                            <span className="material-icons">timer</span>
                <span>{props.data.objectiveStatus === "completed" ? config.i18n.dashboard?.studentProgression.objectiveCompletedIn : config.i18n.dashboard?.studentProgression.timeSpentOnTheObjective} <span>{moment.duration(props.data.timeSpent, 'seconds').format('mm [min] ss [sec]')}</span> </span></p>
                    </div>
                </div>
                <div className="graphic-container">
                    <div className="legend">
                        <ul>
                            <li>
                                <div className="color"></div>
                                <span>{config.i18n.dashboard?.studentProgression.legend.successLevelExpected}</span>
                            </li>
                            <li>
                                <div className="color"></div>
                                <span>{config.i18n.dashboard?.progressionOverview.legend.progressionAcceleration}</span>
                            </li>
                            <li>
                                <div className="color"></div>
                                <span>{config.i18n.dashboard?.studentProgression.legend.successLevelStudent}</span>
                            </li>
                            <li>
                                <div className="color"></div>
                                <span>{config.i18n.dashboard?.progressionOverview.legend.progressionRegression}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="container">
                        <div className="y axis"><span className="arrow-up"></span><span className="text">{config.i18n.dashboard?.studentProgression.success}</span></div>
                        <div className="x axis">
                            <span className="arrow-right"></span>
                            <span className="text">
                                {config.i18n.dashboard?.studentProgression.exercisesDone}
                                <div className="info">
                                    <span className="material-icons">help_outline</span>
                                    <span className="text">{config.i18n.dashboard?.studentProgression.infoBubble}</span>
                                </div>
                            </span>
                        </div>
                        <SimpleBar style={{ height: 370, width: 550, margin: '0 0 0 32px' }} autoHide={false}>
                            <div className="graphic">
                                {
                                    props.data.progression.activities.map((activity, activityIndex) =>
                                        <div className="activity" key={'act-'+ activityIndex}>
                                            <div className="container">
                                                {
                                                    activity.exercises.map((exercise, i) =>
                                                        <div className="exercise" key={'ex-'+i}>
                                                            <div className="bars">
                                                                {/* The height of the bars should have a minimum so that even if the score is 0 the bar still shows */}
                                                                <div className="expected" style={{ height: 15 + exercise.success.expected / maximumSuccess * 85 + '%' }}></div>
                                                                
                                                                <div 
                                                                    className={`${exercise.success.alert} current`} 
                                                                    style={{ height: 15 + exercise.success.current / maximumSuccess * 85 + '%' }}>
                                                                        
                                                                        {exercise.success.alert === "negative" && <span className='material-icons'>trending_down</span>}

                                                                        {exercise.success.alert === "positive" && <span className='material-icons'>trending_up</span>}
                                                                </div>
                                                            </div>
                                                            <div className="title">
                                                                <a rel="noreferrer" href={'/play/WIZARD/' + exercise.id} target='_blank'>{`${config.i18n.dashboard?.studentProgression.exerciseShort} ${getContinuousIndex(props.data.progression.activities, activityIndex, i)}`}</a>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="title">{`${getActivityById(activity.id, data).title.long}`}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </SimpleBar>
                    </div>
                </div>
                <button onClick={() => props.setProgression(undefined)}><span className="material-icons">close</span></button>
            </div>
            <div className="progression-background" onClick={() => props.setProgression(undefined)}></div>
        </div>
    )
}

/**
 * Indexing the exercises in the chart should not reset when the same activity
 * appears twice. This function calculates the index of the exercice, taking
 * possible exercices in the same activity that have already appreared
 */
const getContinuousIndex = (
    activities: Activity[],
    currentActivityIndex: number,
    exerciseIndexInCurrentActivity: number
): number => {
    return activities
        .slice(0, currentActivityIndex)
        .reduce((nbOfExercicesInSameActivity, activity) => {
            if (activity.id !== activities[currentActivityIndex].id) return nbOfExercicesInSameActivity;
            return nbOfExercicesInSameActivity + activity.exercises.length;
        }, 0)
        + exerciseIndexInCurrentActivity
        + 1;
};

export default StudentProgression;
