import React,{useContext} from "react";
import Tooltip from "rc-tooltip";
import { Link } from "react-router-dom";
import {configStore} from "../../../../contexts/ConfigContext";

type TabLinkProps = {
  link: string;
  name: string;
  isAlert: boolean;
  alertMessage: string | undefined;
};

const TabLink: React.FC<TabLinkProps> = ({
  link,
  name,
  isAlert,
  alertMessage,
}) => {

  const {config} = useContext(configStore)

  return (
    <>
      <div className="tab-title">
      {isAlert && (
        <Tooltip
          placement="topLeft"
          overlayStyle={{ backgroundColor: "#FF6138", borderRadius:"10px" }}
          overlayInnerStyle={{borderRadius:"10px", border:"none", display:"flex", alignItems:"center"}}
          overlay={alertMessage}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <span style={{ marginRight: "10px"}}>
            {" "}
            <div className="material-icons">error</div>
          </span>
        </Tooltip>
      )}
        {name}
      </div>


        <Link to={link}>
          <span>{config.i18n.dashboard?.common.class} {name}</span> <span className="material-icons"> chevron_right </span>
        </Link>


    </>
  );
};

export default TabLink;
