import { Activity, Objective } from "@evidenceb/gameplay-interfaces";
import parse from "html-react-parser";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import React, { useContext, useState } from "react";
import { configStore } from "../../../contexts/ConfigContext";
import arrow from "./arrow.svg";
import "./ChatbotDrawer.scss";

type ChatbotDrawerProps = {
    objective: Objective;
    activity: Activity;
};

const DrawerExpandIcon = (props: any) => {
    return (
        <img
            className={"custom-drawer-handle-icon"}
            style={{
                transition: "transform .2s",
                transform: `rotate(${props.open ? 90 : -90}deg)`,
            }}
            src={arrow}
            alt="collapse"
        />
    );
};

const CollapseExpandIcon = (props: any) => {
    return (
        <img
            style={{
                verticalAlign: "-.125em",
                transition: "transform .2s",
                transform: `rotate(${props.isActive ? 180 : 0}deg)`,
            }}
            className={"custom-arrow"}
            src={arrow}
            alt="collapse"
        />
    );
};

const ChatbotDrawer: React.FC<ChatbotDrawerProps> = ({
    activity,
    objective,
}) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const [open, setOpen] = useState<boolean>(true);

    return (
        <Drawer
            className="chatbot-drawer"
            defaultOpen={open}
            level={null}
            placement="left"
            width={340}
            onChange={(open) => setOpen(open as boolean)}
            handler={
                <div className="drawer-handle">
                    <DrawerExpandIcon open={open} />
                </div>
            }
        >
            <Collapse
                expandIcon={CollapseExpandIcon}
                accordion={false}
                defaultActiveKey={["objective", "activity"]}
            >
                <Panel
                    header={objective.title.long}
                    headerClass={"title"}
                    key={"objective"}
                >
                    <div className="panel-section">
                        <p className="description">
                            {parse(objective.description.$html)}
                        </p>
                    </div>
                    {objective.targetedDifficulties ? (
                        <div className="panel-section">
                            <p className="sub-title">
                                {i18n.drawer.targetedDifficulties}
                            </p>
                            <p className="description">
                                {parse(objective.targetedDifficulties?.$html)}
                            </p>{" "}
                        </div>
                    ) : null}
                </Panel>
                <Panel
                    header={activity.title.long}
                    headerClass={"title"}
                    key={"activity"}
                >
                    <div className="panel-section">
                        <p className="description">
                            {parse(activity.description.$html)}
                        </p>
                    </div>
                </Panel>
            </Collapse>
        </Drawer>
    );
};

export default ChatbotDrawer;
