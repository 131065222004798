import React from "react";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

import "./ProgressBar.scss";

const ProgressBar: React.FC<{ completion: number }> = ({ completion }) => {
    return (
        <div className="progress-bar__container">
            <div
                className="progress-bar__completion"
                style={{ width: `${completion}%` }}
            >
                <VisuallyHidden>{`${completion}%`}</VisuallyHidden>
            </div>
        </div>
    );
};

export default ProgressBar;
