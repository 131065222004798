import { ExternalUser, User } from "../interfaces/User";
import { getData,fetchData } from "./request-data";
import * as localStorage from "../utils/localStorage";

// USERS
export const resetUser = async (url:string, userType: string | null) => {
    const token = localStorage.getItem<string>(localStorage.Key.TOKEN);
    const headers = new Headers({ "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' })
    if(userType === "TEACHER" || userType === "STUDENT"){
        const externalUserInfo = await getData<ExternalUser>(url + '/user-info/', headers);
        console.log(`ENT: GET: USER (${externalUserInfo.role})`, externalUserInfo);
       /*  let params = `?provider=${externalUserInfo.provider}&external_id=${externalUserInfo.external_id}` */
        const user = await getData<User>(
            url + '/api/' + externalUserInfo.role + 's/' + externalUserInfo.sub, headers
        );
        if (user) {
            let userBody;
            if(userType === "STUDENT"){
                userBody = {
                    first_name: '',
                    last_name: '',
                    classrooms:[]
                }
            }else{
                userBody = {
                    first_name: '',
                    last_name: '',
                }
            }
            const userUpdated = await fetchData<User>(url + '/api/'+ externalUserInfo.role + 's/' + user.id + '/', "PATCH", userBody, headers);
            console.log(`API: PATCH: USER (${externalUserInfo.role})`, userUpdated);
            window.location.replace('/')
        }
    }else{
        return 'Wrong user type: needs to be TEACHER or STUDENT (case sensitive)'
    }
}

// LOCAL STORAGE
export const clearLocalStorage = (): string => {
    localStorage.clear()
    return "LocalStorage cleared"
}

export const deleteClass = async (url:string, id: string) => {
    let authHeaders = new Headers({ "Authorization": 'Bearer ' + localStorage.getItem<string>(localStorage.Key.TOKEN)})
    let deleteClass = await fetch(url + '/api/classrooms/' + id, { 
        method : "DELETE", 
        headers: authHeaders
    })
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log("CLASSROOMS: API: DELETE: ", data);
        return data;
    })
    .catch((err) => {
        return err;
    });
}