import React, { createContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import { Dashboard } from "../interfaces/Dashboard";

// Context Typing
interface DashboardContext {
  dashboard: Dashboard;
  setDashboard: Dispatch<SetStateAction<Dashboard>>
}

// Initial State
const initialState: Dashboard = {
    classrooms:[]
};

// Context init
const dashboardStore = createContext<DashboardContext>({
    dashboard: initialState,
    setDashboard: () => null
});

// Provider init
const { Provider } = dashboardStore;

// Final setup
const DashboardProvider = ({ children }: { children: JSX.Element }) => {
  const [dashboard, setDashboard] = useState(initialState)
  return <Provider value={{ dashboard, setDashboard }}>{children}</Provider>;
};

export { dashboardStore, DashboardProvider };