import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import { contentPagesStore } from "../../contexts/ContentPagesContext";
import { Title } from "../../interfaces/Config";

import "./ContentPage.scss";
import { getLabel, getUrl } from "../../utils/dataRetrieval";
import { sessionStore } from "../../contexts/SessionContext";

function ContentPage({ title }: Title) {
    const { contentPages } = useContext(contentPagesStore);
    const { session: { userType } } = useContext(sessionStore);

    const location = useLocation();

    // Smooth scroll to a container, smoothScroll is an option (not used when the url contains an anchor).
    const goTo = (id: string, smoothScroll: boolean) => {
        let topNav = document.getElementsByClassName("header-top")[0] as HTMLDivElement;
        let element = document.getElementById(id) as HTMLDivElement;
        var offset = topNav.clientHeight; // sticky topNav height

        if (element) {
            if (smoothScroll) {
                window.scroll({
                    top: element.offsetTop - offset,
                    left: 0,
                    behavior: "smooth",
                });
            } else {
                window.scrollTo(0, element.offsetTop - offset);
            }
        }
    };

    useEffect(() => {
        if (
            !contentPages.some(
                (page) => ("/" + getUrl(page, userType)) === location.pathname && page.navItems && page.navItems.length > 0
            )
        )
            return;

        let topNav = document.getElementsByClassName("header-top")[0] as HTMLDivElement;
        let nav = document.getElementById("content-nav") as HTMLDivElement;
        let sticky = nav.offsetTop;
        let mainNavLinks = document.querySelectorAll(
            "#content-nav li"
        ) as NodeListOf<HTMLLIElement>;

        let goToHash: number;
        // when the url contains an anchor...
        if (location.hash) {
            // ...fire the goTo function with smoothScroll off...
            goToHash = window.setTimeout(() => {
                goTo(location.hash.split("#")[1], false);
            }, 50);
        } else {
            // ...else it means that we need to add a "current" class the 1st item of the internal nav.
            let navLinks = Array.from(mainNavLinks);
            navLinks[0].classList.add("current");
        }

        // ScrollHandler function...
        const scrollHandler = () => {
            // ...add a sticky class when scrolling is happening...
            // ...when scrolling, if the Y position of the page is greater than Y position of the internal nav then make it sticky
            if (window.pageYOffset > sticky) {
                nav.classList.add("sticky");
            } else {
                nav.classList.remove("sticky");
            }

            // ...handling of the add/remove of "current" class on the internal nav items.
            let fromTop = window.scrollY + topNav.clientHeight + 50; //adding 50 to compensate for the 1st link of the internal nav. If not added when you click on another link and you go back to the 1st one, 'current' class is not added, adding 50 resolve this issue.
            mainNavLinks.forEach((link: HTMLLIElement) => {
                let section = document.getElementById(String(link.dataset.id));

                if (
                    section &&
                    section.offsetTop <= fromTop &&
                    section.offsetTop + section.offsetHeight > fromTop
                ) {
                    link.classList.add("current");
                } else {
                    link.classList.remove("current");
                }
            });
        };

        window.addEventListener("scroll", scrollHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
            window.clearTimeout(goToHash);
        };
    }, []);

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>
            <div className="contentPage">
                {contentPages.map((page, i) =>
                    `/${getUrl(page, userType)}` === location.pathname ? (
                        <div key={`content-${i}`}>
                            <header>
                                <h1>{getLabel(page, userType)}</h1>
                                {page.navItems ? (
                                    <nav id="content-nav">
                                        {page.navItems.map((item, j) => (
                                            <li
                                                key={`a-${j}`}
                                                data-id={"container-" + j}
                                                onClick={() =>
                                                    goTo("container-" + j, true)
                                                }
                                            >
                                                {item.icon !== "" ? (
                                                    <span className="material-icons">
                                                        {item.icon}
                                                    </span>
                                                ) : null}
                                                <span>{item.label}</span>
                                            </li>
                                        ))}
                                    </nav>
                                ) : null}
                            </header>
                            {parse(page.content)}
                        </div>
                    ) : null
                )}
            </div>
        </>
    );
}

export default ContentPage;
