import { ActivityShell } from "@evidenceb/gameplay-interfaces";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ChatbotShell from "../../components/ChatbotShell/ChatbotShell";
import ExerciseIdentifier from "../../components/ExerciseIdentifier/ExerciseIdentifier";
import PlayerHeader from "../../components/PlayerHeader/PlayerHeader";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import WizardShell from "../../components/WizardShell/WizardShell";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import { BanditManchotInstanceNotFoundError } from "../../errors";
import useBanditManchotPlaylistManager from "../../hooks/useBanditManchotPlaylistManager";
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";
import { getModuleById, getUrl } from "../../utils/dataRetrieval";
import Loader from "../../components/Loader/Loader";
import { sessionStore } from "../../contexts/SessionContext";

import "./PlaylistPlayer.scss";

const BanditManchotPlaylistPlayer: React.FC = () => {
    const { moduleId } = useParams<{ moduleId: string }>();
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { session: { userType } } = useContext(sessionStore);

    const BMPlaylistManager = useBanditManchotPlaylistManager(moduleId);

    const {
        session: { banditManchot },
    } = useContext(sessionStore);
    if (!banditManchot[moduleId])
        throw new BanditManchotInstanceNotFoundError();

    const [progression, setProgression] = useState<number>();

    // Update the progression when the bandit manchot is updated with a new history
    useEffect(() => {
        const diagnostic = BanditManchotWhisperer.getStudentBasicDiagnostic(
            banditManchot[moduleId].instance
        );
        setProgression(diagnostic.progression);
    }, [banditManchot]);

    if (!BMPlaylistManager.initialized || typeof progression === "undefined")
        return <Loader />;

    const {
        playlist,
        goToNextExercise,
        recordCurrentExerciseResult,
        clearHistory,
    } = BMPlaylistManager;

    // TODO: What happens if the shell type changes mid-playlist ?
    const Shell =
        BMPlaylistManager.playlist.activity.shell === ActivityShell.Chatbot
            ? ChatbotShell
            : WizardShell;

    return (
        <div
            className={`playlist-player-container player-container player-container--bandit-manchot ${
                playlist.activity.shell === ActivityShell.Chatbot
                    ? "chatbot-player"
                    : "wizard-player"
            }`}
        >
            <PlayerHeader>
                <Link
                    /*TEMP find a more elegant way to get the url*/
                    to={ `/${ getUrl( config.pages.find( page => page.type === "MODULELIST" )! , userType)}`}
                    className="player-header__exit"
                >
                    <div className="material-icons exit__icon">arrow_back</div>
                    <div>{config.i18n.exerciseShell.close}</div>
                </Link>
                <div className="player-header__progression">
                    <h2>{getModuleById(moduleId, data).title.short}</h2>
                    {!playlist.isInitialTest && (
                        <ProgressBar completion={progression} />
                    )}
                </div>
                <div></div>
            </PlayerHeader>

            {playlist.currentExercise && (
                <div className="main__wrapper">
                    <main>
                        <Shell
                            onGoToNextExercise={goToNextExercise}
                            onExerciseResult={(result) => {
                                recordCurrentExerciseResult(result);
                                const diagnostic = BanditManchotWhisperer.getStudentBasicDiagnostic(
                                    banditManchot[moduleId].instance
                                );
                                setProgression(diagnostic.progression);
                            }}
                            playlist={playlist}
                            clearHistory={clearHistory}
                        />
                    </main>
                    {
                        config.debug?.exerciseIdentifier ? 
                            <ExerciseIdentifier
                                moduleId={playlist.module.id}
                                objectiveId={playlist.objective.id}
                                activityId={playlist.activity.id}
                                exerciseId={playlist.currentExercise!.id}
                            />
                        : null
                    }
                </div>
            )}
        </div>
    );
};

export default BanditManchotPlaylistPlayer;
