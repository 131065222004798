import React, { useContext } from "react";
import { Emotion, EMOTIONS } from "../useEmotionalReport";
import { configStore } from "../../../contexts/ConfigContext";

import "./EmotionalReportWidget.scss";

// See reference for html & accessibility here: https://stackoverflow.com/questions/45449106/how-to-make-a-ul-list-of-buttons-accessible/45491521

// Do not use Neutral emotion in widget
const _EMOTIONS: {
    [key: string]: {
        i18nKey: "frustrated" | "bored" | "confused" | "neutral";
        Component: () => JSX.Element;
    };
} = {};
Object.keys(EMOTIONS).forEach((emotion) => {
    if (emotion === Emotion.Neutral) return;
    _EMOTIONS[emotion] = EMOTIONS[emotion as Emotion];
});

const EmotionalReportWidget: React.FC<{
    clickedButtons: Emotion[] | null;
    recordReport: (clickedButton: Emotion, recordType: "SELF-REPORT" | "FORCED") => void;
    hideSpeechBubbles?: boolean;
}> = ({ clickedButtons, recordReport, hideSpeechBubbles = false }) => {
    const {
        config: { i18n },
    } = useContext(configStore);

    return (
        <ul
            className="emotional-report emotional-report-widget__container"
            role="listbox"
        >
            {(Object.keys(_EMOTIONS) as Emotion[]).map((emotion, index) => (
                <li
                    className={`${
                        clickedButtons?.includes(emotion)
                            ? "emotional-report-widget__button--clicked"
                            : ""
                    }`}
                >
                    <button
                        onClick={() => recordReport(emotion, "SELF-REPORT")}
                        aria-setsize={Object.keys(_EMOTIONS).length}
                        aria-posinset={index + 1}
                        className={`${
                            hideSpeechBubbles
                                ? "button--hide-speech-bubble"
                                : ""
                        }`}
                        disabled={clickedButtons?.includes(emotion)}
                    >
                        <p>{i18n.exerciseShell.emotionalReport[_EMOTIONS[emotion].i18nKey]}</p>
                        <div className="bot__container">
                            {_EMOTIONS[emotion].Component()}
                        </div>
                    </button>
                </li>
            ))}
        </ul>
    );
};

export default EmotionalReportWidget;
