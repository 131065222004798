import { useContext, useEffect, useState } from "react";
import {
    Exercise,
    ExerciseResult,
    PlaylistManager,
    ComingNext,
    PlaylistExecutionStage,
    Hierarchy,
    ActivityShell,
} from "@evidenceb/gameplay-interfaces/";
import { dataStore } from "../contexts/DataContext";
import { ExerciseNotFoundError, PlayerProgressionError } from "../errors";
import {
    getExercisesInActivity,
    getHierarchy,
    getNextHierarchyLevel,
} from "../utils/dataRetrieval";
import { getResultsForActivity } from "../utils/exerciseUtils";
import { Data } from "../interfaces/Data";

/**
 * This hook is meant to handle the progression through a playlist by a
 * teacher. A teacher:
 * - should be able to review all exercises in an objective
 * - should be able to jump from one exercise to the other freely.
 * @param moduleId
 * @param objectiveId
 */
const useTeacherPlaylistManager = (
    moduleId?: string,
    objectiveId?: string
): PlaylistManager => {
    const { data } = useContext(dataStore);

    /** Current context for the exercise list */
    const [currentHierarchy, setCurrentHierarchy] = useState<
        Omit<Hierarchy, "exercise" | "isInitialTest">
    >(getHierarchy(data, moduleId, objectiveId));
    /** The list of exercises within the current playing activity. */
    const [availableExercises, setAvailableExercises] = useState<
        Exercise<any, any>[]
    >(getExercisesInActivity(currentHierarchy.activity, data));

    const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);
    const [currentTry, setCurrentTry] = useState<number>(1);
    const [currentExerciseResult, setCurrentExerciseResult] = useState<
        ExerciseResult<any> | undefined
    >(undefined);
    const [currentExecutionStage, setCurrentExecutionStage] = useState(
        PlaylistExecutionStage.PlayingCurrentExercise
    );
    const [comingNext, setComingNext] = useState<ComingNext | undefined>(
        undefined
    );

    const [exerciseResults, setExercisesResult] = useState<
        ExerciseResult<any>[]
    >([]);

    // Reset on new try or exercise
    useEffect(() => {
        setCurrentExerciseResult(undefined);
        setComingNext(undefined);
    }, [currentExerciseIndex, currentTry]);

    // Go to next hierarchy level when going past the last exercise of the playing
    // activity
    useEffect(() => {
        if (availableExercises[currentExerciseIndex]) return;

        const newHierarchy = getNextHierarchyLevel(data, currentHierarchy, false);
        if (!newHierarchy) return;
        setCurrentHierarchy(newHierarchy);
        setAvailableExercises(
            getExercisesInActivity(newHierarchy.activity, data)
        );
        setCurrentExerciseIndex(0);
    }, [currentExerciseIndex]);

    // Update available exercises when hierarchy changes
    useEffect(() => {
        setAvailableExercises(
            getExercisesInActivity(currentHierarchy.activity, data)
        );
    }, [currentHierarchy, data]);

    const teacherPlaylistManger: PlaylistManager = {
        playlist: {
            ...currentHierarchy,
            exercises: availableExercises,
            currentExecutionStage: currentExecutionStage,
            currentExercise: availableExercises[currentExerciseIndex],
            currentTry,
            currentExerciseResult,
            comingNext: comingNext,
            exerciseResults: availableExercises
                ? getResultsForActivity(
                      currentHierarchy.activity,
                      exerciseResults
                  )
                : exerciseResults,
        },

        goToExercise: ({ moduleId, objectiveId, activityId, exerciseId }) => {
            if (!availableExercises)
                throw new PlayerProgressionError(
                    "goToExercise called after playlist end"
                );

            const newHierarchy = getHierarchy(
                data,
                moduleId,
                objectiveId,
                activityId
            );
            const exerciseIndex = exerciseId
                ? newHierarchy.activity.exerciseIds.findIndex(
                      (availableExerciseId) =>
                          availableExerciseId === exerciseId
                  )
                : 0;
            if (exerciseIndex === -1) throw new ExerciseNotFoundError();

            setCurrentHierarchy(newHierarchy);
            setCurrentExerciseIndex(exerciseIndex);
            setCurrentExecutionStage(
                PlaylistExecutionStage.PlayingCurrentExercise
            );
        },

        goToNextExercise: () => {
            if (currentHierarchy.activity.shell !== ActivityShell.Chatbot) {
                if (!availableExercises[currentExerciseIndex])
                    throw new PlayerProgressionError(
                        "goToNextExercise called after playlist end"
                    );

                if (!currentExerciseResult)
                    throw new PlayerProgressionError(
                        "Go to next exercise was called before getting an exercise result"
                    );
            }

            if (comingNext === "retry") {
                setCurrentTry((curr) => curr + 1);
            } else {
                setCurrentTry(1);
                setCurrentExerciseIndex((curr) => curr + 1);
            }
            setCurrentExecutionStage(
                PlaylistExecutionStage.PlayingCurrentExercise
            );
        },

        recordCurrentExerciseResult: (partialExerciseResult) => {
            if (!availableExercises[currentExerciseIndex])
                throw new PlayerProgressionError(
                    "recordExerciseResult called after the playlist end"
                );

            const exerciseResult: ExerciseResult<any> = {
                ...partialExerciseResult,
                activityId: currentHierarchy.activity.id,
                exerciseId: availableExercises[currentExerciseIndex].id,
                try: currentTry,
                feedback: partialExerciseResult.correct
                    ? availableExercises[currentExerciseIndex].feedback[
                          currentTry - 1
                      ]?.correct
                    : availableExercises[currentExerciseIndex].feedback[
                          currentTry - 1
                      ]?.incorrect,
            };
            setCurrentExerciseResult(exerciseResult);
            setExercisesResult((curr) => [...curr, exerciseResult]);
            setCurrentExecutionStage(
                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
            );

            const comingNext = getWhatsComingNext(
                data,
                currentHierarchy,
                availableExercises,
                currentExerciseIndex,
                currentTry,
                exerciseResult
            );
            setComingNext(comingNext);

            // Hack to make retry work with automated progression.
            // TODO: rework workflow logic to avoid this
            // if (currentHierarchy.activity.shell === ActivityShell.Chatbot) {
            //     if (comingNext === "retry") {
            //         setCurrentTry((curr) => curr + 1);
            //     } else {
            //         setCurrentTry(1);
            //         setCurrentExerciseIndex((curr) => curr + 1);
            //     }
            //     setCurrentExecutionStage(
            //         PlaylistExecutionStage.PlayingCurrentExercise
            //     );
            // }
        },
    };
    return teacherPlaylistManger;
};

const getWhatsComingNext = (
    data: Data,
    hierarchy: Omit<Hierarchy, "exercise" | "isInitialTest">,
    availableExericses: Exercise<any, any>[],
    currentExerciseIndex: number,
    currentTry: number,
    currentResult?: ExerciseResult<any>
): PlaylistManager["playlist"]["comingNext"] => {
    if (!availableExericses[currentExerciseIndex] || !currentResult)
        return undefined;
    if (
        !currentResult.correct &&
        currentTry <
            availableExericses[currentExerciseIndex]?.executionOptions!
                .numberOfTries!
    )
        return "retry";
    if (currentExerciseIndex === availableExericses.length - 1) {
        const newHierarchy = getNextHierarchyLevel(data, hierarchy, false);
        if (!newHierarchy) return "endOfPlaylist";
        return "nextActivity";
    }
    return "nextExercise";
};

export default useTeacherPlaylistManager;
