import React, { useRef, useEffect, useState, useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Page } from "../../interfaces/Config";
import {configStore} from '../../contexts/ConfigContext';
import {sessionStore} from '../../contexts/SessionContext';
import * as localStorage from "../../utils/localStorage";
import "./Header.scss";
import { getLabel, getUrl } from "../../utils/dataRetrieval";

interface HeaderProps {
    logo: string;
    pages: Page[];
}

function Header({ logo, pages }: HeaderProps) {

    const {config} = useContext(configStore);
    const {session} = useContext(sessionStore);
    const menuContainer = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const [url, setUrl] = useState<string>()
    const [logoutUrl, setLogoutUrl] = useState<string>()

    const toggleMobileMenuHandler = () => {
        menuContainer.current?.classList.toggle("show");
    };
    const logoutHandler = () => {
        toggleMobileMenuHandler();
    };

    useEffect(() => {
        setUrl(location.pathname)
    }, [location])

    useEffect(() => {
        
        let logoutRedirect = config.i18n.misc.logoutUrl.find(item => item.provider === session.appProvider)
        if(logoutRedirect){
            setLogoutUrl(logoutRedirect.url)
        }
    }, [])

    return (
        <header className={`header-top`}>
            <div className="container">
                <div className="logo">
                    {
                        session.userType === "TEACHER" ? <Link to={`/`}> <img src={logo} /> </Link> : <img src={logo} />
                    }
                </div>
                <button
                    onClick={toggleMobileMenuHandler}
                    className="mobileMenuBtn"
                >
                    <span className="material-icons">dehaze</span>
                </button>
                <div ref={menuContainer} className="menu-container">
                    <nav>
                        {pages.map((page, i) =>
                            page.type !== "LOGOUT" && page.inTopNav && page.authorized?.find(user => user === session.userType) ? (
                                <li
                                    onClick={toggleMobileMenuHandler}
                                    key={`navItem-${i}`}
                                >
                                    <NavLink exact to={`/${getUrl(page, session.userType)}`}>
                                        {getLabel(page, session.userType)}
                                    </NavLink>
                                </li>
                            ) : page.type === "LOGOUT" && page.inTopNav && page.authorized?.find(a => a === session.userType) && logoutUrl ? (
                                <button key={`navItem-${i}`} onClick={logoutHandler} >
                                    <a href={`${logoutUrl}`}>
                                        <span className="label"><span className="material-icons icon">logout</span>
                                        {config.i18n.misc.logout}</span>
                                    </a>
                                </button>
                            ) : null
                        )}
                    </nav>
                    <button onClick={toggleMobileMenuHandler} className="closeMobileMenuBtn" >
                        <span className="material-icons">clear</span>
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;
