export function getData<T>(url: string, headers?: Headers): Promise<T> {
    return fetch(url, { 
            method : "GET", 
            headers: headers,
            cache:"no-cache"
        })
        .then((response) => {
            return response.json() as Promise<T>;
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            return err;
        });
}
export function fetchData<T>(url: string, method: "POST" | "PATCH", body: any, headers: Headers): Promise<T> {
    return fetch(url, { 
            method : method, 
            headers: headers,
            body: JSON.stringify(body),
        })
        .then((response) => {
            return response.json() as Promise<T>;
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            return err;
        });
}