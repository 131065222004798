import React from "react";
import { EmotionalReportController } from "./useEmotionalReport";
import EmotionalReportPopup from "./EmotionalReportPopup/EmotionalReportPopup";
import EmotionalReportWidget from "./EmotionalReportWidget/EmotionalReportWidget";

import "./EmotionalReport.scss";

// [WARNING] This feature shouldn't be widely be activated unless the strategy
// for getting result statements is reviewed

/**
 * The EmotionalReport component displays a widget in the student player that
 * allows the student to select an emotion. The emotion is recorded in the LRS.
 *
 * It should be used with the useEmotionalReport hook that provides the props
 * for the component and funcitons that allow the EmotionalReport to react to
 * user events.
 *
 * It is a feature of athena and can be activated with the
 * config.feature.emotionalReport flag.ß
 */
const EmotionalReport: React.FC<
    Omit<EmotionalReportController, "recordValidatedExercise"> & {
        hideSpeechBubbles?: boolean;
    }
> = (props) => {
    return props.showEmotionalReportPopup ? (
        <EmotionalReportPopup {...props} />
    ) : props.showEmotionalReportWidget ? (
        <EmotionalReportWidget {...props} />
    ) : null;
};

export default EmotionalReport;
