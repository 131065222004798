import {
    initBanditManchot,
    loadAllModulesHistory,
} from "@evidenceb/bandit-manchot/build/bandit-manchot-whisperer";
import React, { useContext } from "react";
import { sessionStore } from "../../../contexts/SessionContext";
import {
    filterStatementsByModule,
    statementsToHistory,
} from "../../../utils/tracking";
import { History } from "@evidenceb/bandit-manchot";
import { dataStore } from "../../../contexts/DataContext";
import { configStore } from "../../../contexts/ConfigContext";
import * as localStorage from "../../../utils/localStorage";
import * as Sentry from "@sentry/react";
import useStatements from "../../../hooks/useStatements";

const UseLocalHistory: React.FC = () => {
    const {
        session: { banditManchot, flags, evidencebId },
        setSession,
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { getResultStatements } = useStatements();

    (window as any).HISTORY = {
        useLocalHistory: async () => {
            if (flags.useHistoryFrom === "localHistory") return;

            const statements = await getResultStatements();
            const history: History = {};
            Object.keys(banditManchot).forEach((moduleId) => {
                history[moduleId] = statementsToHistory(
                    filterStatementsByModule(statements, moduleId)
                );
            });
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Use local history",
                level: Sentry.Severity.Info,
                data: { history },
            });
            const newBM = await initBanditManchot(config.ai!, data);
            const loadedBM = loadAllModulesHistory(
                newBM,
                history,
                (err, moduleId) => {
                    console.error(`Cannot load history for module ${moduleId}: ` + err);
                }
            );
            setSession((curr) => {
                return {
                    ...curr,
                    banditManchot: loadedBM,
                    initialHistory: history,
                    flags: {
                        ...curr.flags,
                        useHistoryFrom: "localHistory",
                    },
                };
            });
        },

        useLRSHistory: async () => {
            if (flags.useHistoryFrom === "LRS") return;
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Use LRS history",
                level: Sentry.Severity.Info,
            });

            const statements = await getResultStatements();
            const history: History = {};
            Object.keys(banditManchot).forEach((moduleId) => {
                history[moduleId] = statementsToHistory(
                    filterStatementsByModule(statements, moduleId)
                );
            });
            const newBM = await initBanditManchot(config.ai!, data);
            const loadedBM = loadAllModulesHistory(
                newBM,
                history,
                (err, moduleId) => {
                    console.error(`Cannot load history for module ${moduleId}: ` + err);
                }
            );
            setSession((curr) => {
                return {
                    ...curr,
                    banditManchot: loadedBM,
                    initialHistory: history,
                    flags: {
                        ...curr.flags,
                        useHistoryFrom: "LRS",
                    },
                };
            });
        },

        clearLocalHistory: async () => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Clear local history",
                level: Sentry.Severity.Info,
            });

            localStorage.removeItem(localStorage.Key.LOCAL_HISTORY);
            if (flags.useHistoryFrom === "localHistory") {
                const history = Object.keys(banditManchot).reduce(
                    (history, moduleId) => {
                        history[moduleId] = [];
                        return history;
                    },
                    {} as History
                );
                const newBM = await initBanditManchot(config.ai!, data);
                const loadedBM = loadAllModulesHistory(
                    newBM,
                    history,
                    (err, moduleId) => {
                        console.error(`Cannot load history for module ${moduleId}: ` + err);
                    }
                );
                setSession((curr) => {
                    return {
                        ...curr,
                        banditManchot: loadedBM,
                        initialHistory: history,
                        flags: {
                            ...curr.flags,
                        }, // This is to trigger the UI refresh
                    };
                });
            }
        },
    };

    return <></>;
};

export default UseLocalHistory;
