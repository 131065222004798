import React from "react";

import "./CustomSelect.scss";

/**
 * Select component using native select functionalities but adding custom
 * styles.
 * Children should be the various options.
 */
const CustomSelect: React.FC<{
    onSelectOption: (value: string) => void;
    selectedOption: string;
}> = ({ selectedOption, onSelectOption, children }) => {
    return (
        <div className="custom-select">
            <select
                className="custom-select__native-select"
                onChange={(e) => {
                    onSelectOption(e.target.value);
                }}
            >
                {children}
            </select>
            <div className="custom-select__visual-select">
                {selectedOption}
                <div className="visual-select__drop-icon">
                    <span className="material-icons">arrow_drop_down</span>
                </div>
            </div>
        </div>
    );
};

export default CustomSelect;
