import React from 'react'
import CardEntry from "../../../components/CardEntry/CardEntry";
import { IHomeContent } from '../../../interfaces/Home';
import { parse } from "../../../utils/parse-text";
import "./HomeContent.scss";

interface HomeContentProps {
    content: IHomeContent | undefined
}

function HomeContent({ content }: HomeContentProps) {

    return (
        <div className="home-content">
            {
                content ?
                    <>
                        <div className="top-content">
                            <div className="content">
                                <div className="block">
                                    <h1>{parse(content.main.title)}</h1>
                                    <p>{parse(content.main!.text)}</p>
                                </div>
                                <div className="block">
                                    <img src={content.main.img} />
                                </div>
                            </div>
                        </div>
                        <div className="entries">
                            {content.entries.map((entry, i) =>
                                entry.display ? (
                                    <CardEntry
                                        key={`card-${i}`}
                                        title={entry.title}
                                        img={entry.img}
                                        desc={entry.desc}
                                        buttonLabel={entry.buttonLabel}
                                        url={entry.url}
                                    />
                                ) : null
                            )}
                        </div>
                        <div className="secondary-content">
                            {content.secondaries.map((content, i) => (
                                <div className="container" key={`container-${i}`}>
                                    <div className="block">
                                        <div className="img">
                                            <img src={content.img} />
                                        </div>
                                        <div className="content">
                                            <h2>{parse(content.title)}</h2>
                                            {parse(content.content)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                    : null
            }
        </div>
    )
}

export default HomeContent
