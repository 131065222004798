import React from "react";
import { Link } from "react-router-dom";
import "./BarLink.scss";

type BarLinkProps = {
  title: string;
  url: string;
  id: string | number;
};

const BarLink: React.FC<BarLinkProps> = ({ title, url, id }) => {
  return (
    <div className="bar-link-container">
      <Link key={id} to={url}>
        <span className="bar-link-text">{title}</span>
      </Link>
    </div>
  );
};

export default BarLink;
