import { HtmlString, Objective } from "@evidenceb/gameplay-interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { configStore } from "../../contexts/ConfigContext";
import parse from "html-react-parser";
import "./ModuleListItem.scss";

interface ModuleListItemProps {
    id: string;
    title: string;
    description: HtmlString;
    objectives: Objective[];
}

const ModuleListItem = ({
    id,
    title,
    description,
    objectives,
}: ModuleListItemProps) => {
    const { config } = useContext(configStore);
    const [showList, setShowList] = useState<boolean>(false);
    const history = useHistory();

    return (
        <div className={`module-list-item ${showList ? "opened" : "closed"}`}>
            <div className="header" onClick={() => setShowList(!showList)}>
                <button>
                    <span className="icon-container-btn">
                        <span className="material-icons">
                            keyboard_arrow_down
                        </span>
                    </span>
                </button>
                <div className="title">
                    <h2 className="roboto">{title}</h2>
                    <p className="">{parse(description.$html)}</p>
                </div>
            </div>
            <ul>
                {objectives.map((objective, i) => (
                    <li
                        onClick={() => {
                            history.push(`/player/teacher/${id}/${objective.id}`);
                        }}
                        key={`item-${i}`}
                    >
                        <span className="icon-container-btn">
                            <span className="material-icons">
                                arrow_forward
                            </span>
                        </span>
                        <span className="label">
                            {objective.title.long}
                            {config.i18n.misc.colon}
                            {parse(objective.description.$html)}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ModuleListItem;
